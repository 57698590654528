.page-home-page {
	height: 100%;
	min-width: 768px;
	background-color: $c-white;

	.home-page {
		padding: 25px 25px 0;
		display: flex;
		align-items: center;

		&__title {
			color: $c-navy;
			font-size: 60px;
			font-family: "gotham-book";
			display: flex;
			align-items: stretch;

			&:after {
				content: "";
				display: block;
				width: 8px;
				background-color: $c-navy;
			}
		}

		&__subtitle {
			padding: 0 15px;
			font-size: 26.24px;
			font-family: "gotham-bold";

			.username {
				font-size: 20px;
				font-family: "gotham-book";
				color: black;
			}
		}
	}

	.body-content {
		color: $c-body;
		margin: 0 0 25px;
		font-size: 35px;
		font-family: "gotham-bold";
		text-align: center;
		padding-bottom: 50px;

		&__container {
			padding: 25px;
			display: flex;

			@include max-width($scr-lg) {
				flex-direction: column;
			}
		}

		&__bar-title {
			padding: 10px;
			font-size: $text-size-s;
			font-family: "gotham-book";
		}

		&__body-content-left {
			width: 50%;

			@include max-width($scr-lg) {
				width: 100%;
			}

			.title {
				background-color: $c-navy;
				border-radius: 10px 10px 0px 0px;
				color: $c-white;
			}

			.multiple-bar {
				background-color: $c-table-gray;
				height: 500px;
				border-radius: 0px 0px 10px 10px;
			}

			.icons-box {
				font-size: 1rem;
				background-color: white;
				color: black;
				text-align: center;
				max-width: 16.305%;
				margin-right: 4.2%;
				border-radius: 0px 0px 10px 10px;
			}

			.icons-last-box {
				font-size: 1rem;
				background-color: white;
				color: black;
				text-align: center;
				max-width: 16.34%;
				border-radius: 0px 0px 10px 10px;
			}

			.img {
				width: auto;
				height: 100%;
				align-self: center;
			}

			.bar-xaxis {
				margin-left: 1.99%;
				margin-right: 0.4%;
			}

			.bottom-left-title {
				color: $c-black;
				font-size: 14px;
				font-family: "gotham-book";
				text-align: left;
				display: flex;
				align-items: center;
			}

			.bottom-left-value {
				color: $c-light-navy;
				font-size: 47px;
				height: 55px;
				font-family: "gotham-bold";
				border-left: 5px solid $c-light-navy;
				display: flex;
				align-items: center;
			}

			.bottom-left {
				max-height: 65px;
				display: flex;
				align-items: center;
			}
		}

		&__body-content-right {
			width: 50%;
			margin: 0 15px;

			@include max-width($scr-lg) {
				margin: 25px 0;
				width: 100%;
			}

			.multiple-schedule {
				border-radius: 10px;
				background-color: $c-table-gray;
			}

			.table-title {
				color: $c-navy;
				font-size: 25px;
			}

			.table-content {
				padding: 25px;
				text-align: left;
			}

			.bottom-right-title {
				color: $c-black;
				font-size: 18px;
				font-family: "gotham-book";
				text-align: left;
				display: flex;
				align-items: center;
				margin-left: 5px;
				// margin:10px
			}

			.bottom-right-value {
				color: $c-light-navy;
				font-size: 47px;
				font-family: "gotham-bold";
				border-left: 5px solid $c-light-navy;
				height: 40px;
				display: flex;
				align-items: center;
			}

			.bottom-right {
				margin: 25px 0;
				padding: 15px;
				background-color: $c-table-gray;
				border-radius: 10px;
				display: flex;
				align-items: center;
			}

			.bottom-right-button {
				height: 40px;
			}
		}
	}
}
