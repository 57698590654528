.page-merchant-add {
	height: 100%;
	width: 100%;
	background-color: #ffffff;

	.fixed-content {
		width: 100%;
		padding: 0 20px 30px;
	}

	.top-context {
		padding: 20px 0;
		display: flex;
		align-items: center;
		justify-content: space-between;

		&__wrapper {
			display: flex;
			align-items: center;
		}
	}

	.title {
		font-size: 48px;
		color: $c-navy;
		font-family: "gotham-light";
	}

	.search-button {
		height: auto;
		margin-left: 20px;
		display: flex;
		align-items: center;

		.app-button__button {
			margin-left: 15px;
			color: $c-navy;
			min-width: 200px;
			border: 1px solid $c-navy;
			background-color: $c-white;
		}
	}

	.input-form {
		padding: 20px;
		border-radius: 8px;
		background-color: #f6f6f6;
	}

	.table-layout {
		overflow-y: auto;
		height: 500px;
		border-radius: 15px;
		width: 100%;
	}

	.table-layout::-webkit-scrollbar {
		display: none;
	}

	table {
		width: 100%;
		padding: 5px 15px 0px 15px;
		border-collapse: collapse;
	}

	thead th {
		background-color: #f6f6f6;
		position: sticky;
		top: 0;
	}

	table.center {
		margin-left: auto;
		margin-right: auto;

		th {
			text-decoration: underline;
			font-family: "gotham-bold";
		}

		td {
			font-family: "gotham-book";
		}
	}

	tbody tr:nth-child(odd) {
		background-color: #dddddd;
	}

	tr:nth-child(even) {
		background-color: #f6f6f6;
	}

	th,
	td {
		text-align: center;
		padding: 10px 0px 10px 0px;
		font-size: 18px;
	}

	.alignBottom {
		align-self: flex-end;
	}

	.text-field {
		font-size: 25px;
		font-family: "gotham-bold";
		align-self: flex-end;
	}

	.check-box {
		transform: scale(1.5);
		-ms-transform: scale(1.5);
		-webkit-transform: scale(1.5);
		-o-transform: scale(1.5);
		-moz-transform: scale(1.5);
		transform-origin: 0 0;
		-ms-transform-origin: 0 0;
		-webkit-transform-origin: 0 0;
		-o-transform-origin: 0 0;
		-moz-transform-origin: 0 0;
	}

	.pagination-button {
		display: inline-block;
		margin-bottom: 10px;
		float: right;

		span {
			font-family: "gotham-bold";
		}

		button {
			text-decoration: none;
			transition: background-color 0.3;
			border: none;
			background-color: transparent;
		}

		button:hover {
			background-color: #ddd;
		}
	}

	.resetPswButton {
		background-color: #9d9d9c;
		color: white;
		border: none;
		border-radius: 8px;
		font-family: "gotham-bold";
		padding: 3px 5px 3px 5px;
		width: 138px;
		height: 30px;
	}

	.cards {
		display: flex;
	}

	.card-wrapper {
		width: 50%;
		padding-right: 50px;
	}

	.input-content {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.textarea-container {
		width: 100% !important;

		textarea {
			outline: none;
			min-height: 200px;
		}
	}

	.input-container {
		width: 100%;

		span {
			min-height: 16px;
			color: $c-black;
			font-family: $gotham-weight-200;
		}
		input {
			border: 0;
			width: 100%;
			outline: none;
			font-family: "gotham-book";
			background-color: transparent;

			&:disabled {
				background-color: #e6e6e6;
			}
		}

		textarea {
			border: 0;
			width: 100%;
			outline: none;
		}
	}

	.input-wrapper {
		width: 100%;
		min-height: 55px;
		padding: 10px;
		margin: 10px 0 0;
		border-radius: 8px;
		border: #c9d2de 1px solid;
		background-color: $c-white;
		display: flex;
		align-items: center;
		justify-content: center;

		&--error {
			border: 1px solid $c-red;
		}

		&--disabled {
			background-color: #e6e6e6;
		}

		&--checkbox {
			border: 0;
			padding: 0;
			background-color: transparent;
			align-items: flex-start;
			justify-content: flex-start;

			input {
				width: auto !important;
			}
		}

		&__text {
			width: 100%;
			margin: 0;
			text-align: left;
		}

		.select-field {
			width: 100%;
			text-align: left;
			border: 0;
			font-size: 18px;
			outline: none;
			font-family: $gotham-weight-100;
		}
	}

	.merchant-input {
		position: relative;
		max-width: 420px;
		.merchant-input-text {
			height: 40px;
			border-radius: 4px;
			border: 1px solid $c-border-gray;
			outline: none;
			font-family: $avenir-next-weight-500;
			font-size: 16px;
			color: $c-dark-gray;
			padding: 10px 13px 8px 13px;
			width: 100%;

			&::placeholder {
				color: $c-weathered-stone;
			}

			&:focus {
				border: 2px solid $c-cyan;
			}
		}

		.merchant-input-text-error {
			height: 40px;
			border-radius: 4px;
			border: 2px solid $c-red !important;
			outline: none;
			font-family: $avenir-next-weight-500;
			font-size: 16px;
			color: $c-dark-gray;
			padding: 10px 13px 8px 13px;
			width: 100%;

			&::placeholder {
				color: $c-weathered-stone;
			}

			&:focus {
				border: 2px solid $c-cyan;
			}
		}

		.merchant-input-select {
			font-family: $avenir-next-weight-500;
			font-size: 16px;
			// background-color: transparent;
			cursor: pointer;

			&__input {
				height: 40px;
				width: 100%;
				border-radius: 4px;
				border: 1px solid $c-border-gray;
				color: $c-dark-gray;
				align-items: center;
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				padding: 10px 13px 8px 13px;

				&--selected {
					border-radius: 4px 4px 0px 0px;
					border-top: 2px solid $c-cyan;
					border-left: 2px solid $c-cyan;
					border-right: 2px solid $c-cyan;
					border-bottom: 0px;
				}

				&--placeholder {
					color: $c-weathered-stone;
				}
			}

			&__list {
				width: 100%;
				color: $c-gray;
				background: $c-lynx-white;
				border-radius: 0px 0px 4px 4px;
				border-top: 0px;
				border-bottom: 2px solid $c-cyan;
				border-left: 2px solid $c-cyan;
				border-right: 2px solid $c-cyan;
				padding: 0px;
				margin: 0px;
			}

			&__option {
				height: 40px;
				padding: 10px 13px 8px 13px;

				&:hover,
				&:active {
					background: $c-cyan;
					color: $c-white;
				}
			}
		}

		.merchant-input-select-error {
			font-family: $avenir-next-weight-500;
			font-size: 16px;
			// background-color: transparent;
			cursor: pointer;

			&__input {
				height: 40px;
				width: 100%;
				border-radius: 4px;
				border: 2px solid $c-red !important;
				color: $c-dark-gray;
				align-items: center;
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				padding: 10px 13px 8px 13px;

				&--selected {
					border-radius: 4px 4px 0px 0px;
					border-top: 2px solid $c-cyan;
					border-left: 2px solid $c-cyan;
					border-right: 2px solid $c-cyan;
					border-bottom: 0px;
				}

				&--placeholder {
					color: $c-weathered-stone;
				}
			}

			&__list {
				width: 100%;
				color: $c-gray;
				background: $c-lynx-white;
				border-radius: 0px 0px 4px 4px;
				border-top: 0px;
				border-bottom: 2px solid $c-cyan;
				border-left: 2px solid $c-cyan;
				border-right: 2px solid $c-cyan;
				padding: 0px;
				margin: 0px;
			}

			&__option {
				height: 40px;
				padding: 10px 13px 8px 13px;

				&:hover,
				&:active {
					background: $c-cyan;
					color: $c-white;
				}
			}
		}
	}
}
