.page-login {
  width: 100%;

  &__input-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  &__button-container {
    display: flex;
    justify-content: space-between;

    @include max-width($scr-md) {
      flex-direction: column;
    }
  }

  &__forgot-password {
    margin: 0 10px;
    color: $c-white;
    font-size: $text-size-s;
    font-family: $avenir-next-weight-300;
    cursor: pointer;
  }

  &__login-button {
    width: 100%;
    max-width: 200px;
    min-width: 150px;

    @include max-width($scr-md) {
      margin: 10px 0;
      max-width: 100%;
    }
  }
}
