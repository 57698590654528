.page-calander-settings {
	background-color: white;

	.calendar-settings {
		padding: 20px;

		&__header {
			padding: 20px 0;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		&__title {
			color: $c-navy;
			font-size: $text-size-xxxl;
			font-family: $gotham-weight-100;
			text-align: right;
			display: flex;
			justify-content: flex-end;
		}

		&__update-button {
			min-width: 150px;
			padding: 15px 20px;
			color: $c-white;
			background-color: $c-navy;
			border-radius: 8px;
			border: 0;
			font-family: $gotham-weight-200;
		}

		&__body {
			padding: 20px;
			border-radius: 10px;
			background-color: #f6f6f6;
		}

		&__time {
			display: flex;
			align-items: center;
			flex-wrap: wrap;
		}

		&__label {
			margin: 0;
			color: $c-black;
			font-family: $gotham-weight-300;
		}

		&__input {
			width: 150px;
			margin: 5px 5px 5px 0;
			padding: 10px;
			border-radius: 8px;
			border: 1px solid #c9d2de;
			background-color: $c-white;
			font-family: $gotham-weight-200;
			outline: none;
		}

		&__button {
			width: 150px;
			margin: 15px 0;
			padding: 10px 20px;
			border: 0;
			color: $c-white;
			border-radius: 8px;
			background-color: $c-navy;
			flex-shrink: 0;
		}

		&__footer {
			padding: 20px;
			margin-top: 20px;
			border-radius: 10px;
			background-color: #f6f6f6;
		}

		&__slots {
			margin-right: 20px;
		}

		&__input-wrapper {
			display: flex;
			flex-wrap: wrap;
			align-items: center;

			.calendar-settings__input {
				width: 250px;
			}
		}

		&__updated {
			margin-top: 20px;
			text-align: right;
			font-family: $gotham-weight-200;
		}
	}
}

.calendar-settings-popup {
	//
	&__content {
		width: 100%;
		max-width: 1024px;
		margin: 0 auto;

		.modal-header {
			border: 0;
			display: flex;
			justify-content: flex-end;
		}

		.modal-body {
			//
			.body__schedule {
				//
				.time {
					width: 150px;
					padding: 10px;
					margin: 5px 5px 5px 0;
					border-radius: 8px;
					border: 1px solid #c9d2de;
					background-color: $c-white;
					font-family: $gotham-weight-200;
                    cursor: pointer;
				}

				.active {
					color: $c-navy;
					border: 2px solid $c-navy;
					font-family: $gotham-weight-300;
				}
			}
		}

		.modal-footer {
			border: 0;

			button {
				width: 150px;
				margin: 15px;
				padding: 10px 20px;
				border: 0;
				color: $c-white;
				border-radius: 8px;
				background-color: $c-navy;
			}
		}
	}
}
