.app-button {
	&__transparent-button {
		display: flex;
		flex-direction: row;
		width: 100%;
		height: 100%;
		background-color: transparent;
		border: none;
		font-size: $text-size-body;
		font-family: $avenir-next-weight-600;

		&:hover {
			opacity: 0.8;
			cursor: pointer;
		}
		&:active {
			opacity: 0.6;
		}
	}

	&__primary-text {
		color: $c-cyan;
	}

	&__warning-text {
		color: $c-red;
	}

	&--black-text {
		color: $c-black;
	}

	&__icon-wrapper {
		margin-left: 5px;
	}

	&--rotate {
		transform: rotateZ(180deg);
	}

	&__button {
		width: 100%;
		height: 100%;
		padding: 15px !important;
		border: none;
		border-radius: 8px;
		font-size: $text-size-body;
		font-family: $avenir-next-weight-500;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;

		&:hover {
			opacity: 0.8;
			cursor: pointer;
		}
		&:active {
			opacity: 0.6;
		}
	}

	&__primary-button {
		background-color: $c-navy;
		color: $c-white;
	}

	&__warning-button {
		background-color: $c-red;
		color: $c-white;
	}

	&__white-button {
		background-color: $c-white;
		color: $c-gray;
	}

	&__orange-button {
		background-color: $c-orange;
		color: $c-white;
		font-size: 18px;
		font-family: "gotham-bold";
	}

	&__dimmed-button {
		background-color: $c-grayish-blue;
		color: $c-white;
		font-size: 18px;
		font-family: "gotham-bold";
	}

	&--disable {
		background-color: #a2b1c5;
	}

	&--disable-transparent {
		background-color: inherit !important;
		color: $c-table-icon !important;
		border: 2px solid $c-table-icon !important;
		cursor: default !important;
		opacity: 1 !important;
	}

	&__update-button {
		background-color: $c-beige;
		color: $c-white;
		font-size: 35px;
		font-family: "gotham-bold";
	}
}
