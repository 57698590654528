.app-react-table {
	&__table-wrapper {
		width: 100%;
		border-spacing: 0;
		border-radius: 10px;
	}

	.react-table {
		&__header-title {
			text-decoration: none !important;
			font-size: $text-size-body;
			font-family: "gotham-book";
		}
	}

	.header {
		&__row {
			background-color: $c-light-gray;
			border-top-left-radius: 10px;
			border-top-right-radius: 10px;
			color: $c-darker-gray;
			font-size: $text-size-body;
			font-family: "gotham-book";
			height: 66px;
			display: flex;
			align-items: center;

			&-header {
				padding: 0 15px;
				display: flex;
				align-items: center;
				justify-content: flex-start;
			}
		}
	}

	.body {
		display: block;

		&__row {
			color: $c-darker-gray;
			font-size: $text-size-m;
			font-family: $gotham-weight-200;
			height: 66px;
			display: flex;

			align-items: center;

			&:nth-child(odd) {
				background-color: $c-table-gray;
			}

			&:last-child {
				border-bottom-left-radius: 6px;
				border-bottom-right-radius: 6px;
			}

			&-data {
				padding: 0 15px;
				word-break: break-all;
				font-size: $text-size-s;
				font-family: "gotham-light";

				&_empty-message {
					// word-break: break-all;
					display: flex;
					justify-content: center;
					align-items: center;
					height: 440px;
					font-size: 25px;
					font-family: "gotham-bold";
					color: black;
				}
			}
		}
	}

	.dashBoardHeader {
		&__row {
			background-color: $c-white;
			border-top-left-radius: 10px;
			border-top-right-radius: 10px;
			color: $c-darker-gray;
			font-family: $gotham-weight-400;
			font-size: $text-size-m;
			height: 40px;
			display: flex;
			align-items: center;

			&-header {
				display: flex;
				align-items: center;
				justify-content: flex-start;

				&:first-child {
					padding-left: 31px;
				}
			}
		}
	}

	.dashBoardBody {
		height: 145px;
		display: block;
		background-color: $c-white;
		&__row {
			color: $c-darker-gray;
			font-size: $text-size-m;
			font-family: $gotham-weight-200;
			background-color: $c-white;
			height: 45px;
			display: flex;
			align-items: center;

			&:nth-child(odd) {
				background-color: $c-light-gray;
			}

			&:last-child {
				border-bottom-left-radius: 6px;
				border-bottom-right-radius: 6px;
			}

			&-data {
				// text-align: center;
				word-break: break-all;
				display: flex;
				justify-content: flex-start;
				align-items: center;
				&:first-child {
					padding-left: 31px;
				}
				&_empty-message {
					display: flex;
					align-items: center;
					justify-content: center;
					height: 127px;
					width: 100%;
					font-size: 18px;
					font-family: "gotham-book";
					color: black;
				}
			}
		}
	}

	.customerDetailsHeader {
		&__row {
			padding: 15px 0;
			color: $c-darker-gray;
			font-size: $text-size-m;
			background-color: $c-white;
			font-family: $gotham-weight-400;

			&-header {
				padding: 0 15px;
			}
		}
	}

	.customerDetailsBody {
		display: block;
		&__row {
			color: $c-darker-gray;
			font-size: $text-size-m;
			font-family: $gotham-weight-200;
			background-color: $c-white;
			height: 60px;
			display: flex;
			align-items: center;

			&:nth-child(odd) {
				background-color: $c-light-gray;
			}

			&:last-child {
				border-bottom-left-radius: 6px;
				border-bottom-right-radius: 6px;
			}

			&-data {
				word-break: break-all;
				display: flex;
				align-items: center;
				justify-content: flex-start;

				&:first-child {
					padding-left: 31px;
				}

				&_empty-message {
					display: flex;
					justify-content: center;
					align-items: center;
					height: 370px;
					font-size: 25px;
					font-family: "gotham-bold";
					color: black;
				}
				// &:last-child{
				//   margin-right: 31px;
				// }
			}
		}
	}

	.userContractDetailsHeader {
		&__row {
			background-color: $c-table-gray;
			border-top-left-radius: 10px;
			border-top-right-radius: 10px;
			color: $c-darker-gray;
			font-family: $gotham-weight-400;
			font-size: $text-size-m;
			height: 66px;
			display: flex;
			align-items: center;

			&-header {
				padding: 0 15px;
				display: flex;
				justify-content: flex-start;
				align-items: center;
			}
		}
	}

	.userContractDetailsBody {
		display: block;
		&__row {
			color: $c-dark-gray;
			font-size: $text-size-m;
			font-family: $gotham-weight-200;
			background-color: $c-table-gray;
			height: 60px;
			display: flex;
			align-items: center;

			&:nth-child(odd) {
				background-color: $c-light-gray;
			}

			&:last-child {
				border-bottom-left-radius: 6px;
				border-bottom-right-radius: 6px;
			}

			&-data {
				word-break: break-all;
				display: flex;
				justify-content: flex-start;
				align-items: center;
				&:first-child {
					padding-left: 31px;
				}

				&_empty-message {
					display: flex;
					justify-content: center;
					align-items: center;
					height: 470px;
					font-size: 25px;
					font-family: "gotham-bold";
					color: black;
				}
			}
		}
	}

	.paymentReturnUploadHeader {
		&__row {
			background-color: $c-white;
			border-top-left-radius: 10px;
			border-top-right-radius: 10px;
			color: $c-darker-gray;
			font-family: $gotham-weight-400;
			font-size: $text-size-m;
			height: 66px;
			display: flex;
			align-items: center;

			&-header {
				display: flex;
				justify-content: flex-start;
				align-items: center;

				&:first-child {
					padding-left: 31px;
				}
				&:nth-child(5),
				&:nth-child(4) {
					justify-content: center;
				}
			}
		}
	}

	.paymentReturnUploadHeader1 {
		&__row {
			background-color: $c-white;
			border-top-left-radius: 10px;
			border-top-right-radius: 10px;
			color: $c-darker-gray;
			font-family: $gotham-weight-400;
			font-size: $text-size-m;
			height: 66px;
			display: flex;
			align-items: center;

			&-header {
				display: flex;
				justify-content: flex-start;
				align-items: center;

				&:first-child {
					padding-left: 31px;
				}
				// &:nth-child(5),
				// &:nth-child(4) {
				//   justify-content: center;
				// }
			}
		}
	}

	.paymentReturnUploadBody {
		display: block;
		&__row {
			padding: 10px;
			color: $c-darker-gray;
			font-size: $text-size-m;
			font-family: $gotham-weight-200;
			background-color: $c-white;
			display: flex;
			align-items: center;

			&:nth-child(odd) {
				background-color: $c-table-gray;
			}

			&-data {
				word-break: break-all;
				display: flex;
				justify-content: flex-start;
				align-items: center;

				&_empty-message {
					// word-break: break-all;
					display: flex;
					justify-content: center;
					align-items: center;
					height: 370px;
					font-size: 25px;
					font-family: "gotham-bold";
					color: black;
				}
			}
		}
	}

	.invoiceHistoryHeader {
		&__row {
			background-color: $c-white;
			border-top-left-radius: 10px;
			border-top-right-radius: 10px;
			color: $c-darker-gray;
			font-family: $gotham-weight-400;
			font-size: $text-size-s;
			height: 66px;
			display: flex;
			align-items: center;
			max-width: 932px;

			&-header {
				display: flex;
				justify-content: center;
				align-items: center;
				text-align: center;

				&:first-child {
					display: flex;
					justify-content: center;
					align-items: center;
				}
				&:last-child {
					margin-right: 10px;
				}
			}
		}
	}

	.invoiceHistoryBody {
		display: block;

		&__strike {
			width: inherit;

			//content: url("../../assets/images/components/strike.png");
			background-repeat: repeat-x;
			background-position: 50% 50%;
			z-index: 100;
			max-width: 932px;
			position: absolute;
		}
		&__text-red {
			color: red;
		}
		&__row {
			color: $c-darker-gray;
			font-size: $text-size-s;
			font-family: $gotham-weight-200;
			background-color: $c-white;
			height: 60px;
			display: flex;
			z-index: 1;
			justify-content: center;
			align-items: center;
			max-width: 932px;

			&:nth-child(odd) {
				background-color: $c-light-gray;
			}

			&:last-child {
				border-bottom-left-radius: 6px;
				border-bottom-right-radius: 6px;
			}

			&-data {
				// text-align: center;
				word-break: break-all;
				display: flex;
				justify-content: center;
				align-items: center;
				z-index: 1;
				&:first-child {
					display: flex;
					justify-content: center;
					align-items: center;
				}

				&_empty-message {
					// word-break: break-all;
					display: flex;
					justify-content: center;
					align-items: center;
					height: 520px;
					font-size: 25px;
					font-family: "gotham-bold";
					color: black;
					max-width: 932px;
				}
				// &:last-child{
				//   margin-right: 31px;
				// }
			}
		}
	}

	.body::-webkit-scrollbar,
	.dashBoardBody::-webkit-scrollbar,
	.customerDetailsBody::-webkit-scrollbar,
	.paymentReturnUploadBody::-webkit-scrollbar,
	.userContractDetailsBody::-webkit-scrollbar,
	.invoiceHistoryBody::-webkit-scrollbar {
		// display: none;
		width: 10px;
		height: 10px;
	}
	.body::-webkit-scrollbar-track,
	.dashBoardBody::-webkit-scrollbar-track,
	.customerDetailsBody::-webkit-scrollbar-track,
	.paymentReturnUploadBody::-webkit-scrollbar-track,
	.userContractDetailsBody::-webkit-scrollbar-track,
	.invoiceHistoryBody::-webkit-scrollbar-track {
		background: #f1f1f1;
		border-radius: 8px;
	}
	.body::-webkit-scrollbar-thumb,
	.dashBoardBody::-webkit-scrollbar-thumb,
	.customerDetailsBody::-webkit-scrollbar-thumb,
	.paymentReturnUploadBody::-webkit-scrollbar-thumb,
	.userContractDetailsBody::-webkit-scrollbar-thumb,
	.invoiceHistoryBody::-webkit-scrollbar-thumb {
		background: #888;
		border-radius: 8px;
	}
}
