@import "styles/font.scss";
@import "styles/variables.scss";
@import "styles/mixin.scss";
.page-forgot-password {
	height: 100%;

	@include max-width($scr-md) {
		overflow-y: scroll;
	}

	.forgot-password {
		background-color: $c-white;

		&__background {
			width: 50%;
			background-size: cover;
			background-position: center;
			background-repeat: no-repeat;
			background-image: url(../../../assets/images/pages/authentication/page-forgot-password-background.jpg);

			@include max-width($scr-md) {
				width: 100%;
				height: 300px;
			}
		}

		&__title {
			margin: 0;
			padding: 20px;
			color: $c-white;
			text-align: right;
			line-height: 1.3;
			font-size: $text-size-xxl;
			font-family: $avenir-next-weight-300;
		}

		&__icon {
			width: 100%;
			min-width: 150px;
			max-width: 190px;
			padding: 20px;
		}

		&__sub-title {
			color: $c-light-navy;
			font-size: $text-size-l;
			font-family: $gotham-weight-300;
		}

		&__description {
			color: $c-black;
			font-size: $text-size-m;
			font-family: $gotham-weight-100;
		}

		&__content {
			width: 50%;
			padding: 35px;
			background-color: $c-white;

			@include max-width($scr-md) {
				width: 100%;
			}
		}

		&__content-wrapper {
			padding: 20px;
			border-radius: 8px;
			background-color: $c-light-gray;
		}

		&__input-wrapper {
			padding: 5px;
			border-radius: 8px;
			border: 1px solid #c9d2de;
			display: flex;
			align-items: stretch;

			&--error {
				border-color: $c-red;
			}
		}

		&__input-content {
			width: calc(100% - 100px);
			display: flex;
			align-items: center;
		}

		&__code-button {
			width: 100px;
			color: $c-white;
			border-radius: 8px;
			font-size: $text-size-s;
			font-family: $gotham-weight-200;
			background-color: $c-navy;
			border: 0;

			&:disabled {
				background-color: $c-light-gray;
			}
		}

		&__input-icon {
			width: 30px;
			height: 30px;
			display: block;
		}

		&__error-msg {
			min-height: 12px;
			margin: 5px 0;
			color: $c-red;
			font-size: $text-size-s;
		}

		&__password-requirement-wrapper {
			margin: 15px 0;
			width: 100%;
			display: flex;
			flex-wrap: wrap;
		}

		&__password-requirement {
			width: 50%;
			margin: 5px 0;
			color: #333333;
			font-size: $text-size-s;
			font-family: $gotham-weight-100;

			@include max-width($scr-md) {
				width: 100%;
			}
		}

		&__tick {
			&--error {
				path {
					fill: #c3c4c6;
					stroke: #c3c4c6;
				}
			}
		}

		&__input-container {
			padding: 10px 15px 5px 5px;
			border-radius: 8px;
			border: 1px solid #c9d2de;
			display: flex;
			flex-direction: column;
			align-items: stretch;

			&--error {
				border-color: $c-red;
			}
		}

		&__label-text {
			padding: 0 0.5em;
			color: #6c7a8f;
			font-size: $text-size-xs;
			font-family: $gotham-weight-200;
		}

		.app-forgot-password-input__error {
			display: none;
		}

		.input-70 {
			height: 35px;
			padding: 0 0.5rem;
		}
	}

	header {
		height: 95px;
		background-color: $c-dark-navy;
		display: flex;
		align-items: center;

		img {
			height: 75px;
			padding: 10px;
		}
	}

	.grid-container {
		height: 100vh;
		display: flex;

		@include max-width($scr-md) {
			width: 100%;
			height: auto;
			flex-direction: column;
		}

		.grid-context {
			margin-bottom: 20px;
			display: flex;
			align-items: center;
		}

		.grid-span {
			width: 100%;
			padding: 20px;
			border-radius: 8px;
			background-color: $c-white;
		}

		.app-button__button {
			width: 100%;
			max-width: 250px;
			margin-top: 20px;
			margin-left: auto;

			@include max-width($scr-md) {
				max-width: 100%;
			}
		}
	}
}
