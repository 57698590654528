.application-view {
	height: 100%;
	width: 100%;
	padding: 0 20px 30px;
	background-color: #ffffff;

	.top-context {
		padding: 20px 0;
		display: flex;
		align-items: center;
		justify-content: space-between;

		.title {
			font-size: 48px;
			color: $c-navy;
			font-family: "gotham-light";
		}

		&__wrapper {
			display: flex;
			align-items: stretch;
		}

		.search-button {
			display: flex;
			align-items: stretch;

			.dropdown {
				display: flex;
				align-items: stretch;
			}
		}

		.dropButton,
		.app-button__button {
			border: none;
			margin: 0 0 0 10px;
			width: 190px;
			color: $c-navy;
			border-radius: 8px;
			font-size: $text-size-m;
			background-color: $c-white;
			border: 1px solid $c-navy;
			cursor: pointer;
		}

		.dropdown-content {
			display: none;
			position: absolute;
			background-color: #f1f1f1;
			font-size: 18px;
			box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
			z-index: 1;
			text-align: center;
			border-radius: 15px;
			left: 50% !important;
			right: auto !important;
			transform: translate(-50%, 0) !important;
			span {
				border-bottom: 2px solid #9d9d9c;
				padding: 1px 20px;
				color: $c-beige;
				font-family: "gotham-medium";
				font-size: 20px;
			}
		}

		.dropdown-content a {
			color: #9d9d9c;
			padding: 3px 4px;
			text-decoration: none;
			display: block;
		}

		.dropdown-content a:hover {
			background-color: #f1f1f1;
			border-radius: 15px;
		}

		.show {
			display: block;
			top: 8px;
		}
	}

	.application-detail {
		.tabs {
			width: 100%;
			margin: 15px 0 0;
			display: flex;

			&__tab {
				padding: 20px;
				color: $c-white;
				font-size: $text-size-m;
				font-family: $gotham-weight-200;
				text-align: center;
				white-space: nowrap;
				display: flex;
				align-items: center;
				justify-content: center;
				cursor: pointer;

				&--active {
					color: $c-black;
					background-color: #f6f6f6 !important;
				}
			}

			.tab1 {
				border-top-left-radius: 10px;
				background-color: #b6d3f6;
			}

			.tab2 {
				border-top-right-radius: 10px;
				background-color: #b6d3f6;
			}
		}

		.active-zoom {
			position: absolute;
			top: 20px;
			bottom: 20px;
			left: 20px;
			right: 20px;
		}

		.wrapper {
			padding: 15px;
			border-radius: 8px;
			background-color: #f6f6f6;
			display: flex;

			#zoomMeetingRoot {
				position: relative;
				z-index: 999999999999;
			}

			&--meeting {
				position: relative;
				width: 100%;
				height: 600px;
				align-items: center;
				justify-content: center;
			}

			.join-meeting-button {
				position: absolute;
				height: 55px;
				padding: 15px 20px;
				border: 0;
				color: $c-white;
				border-radius: 10px;
				background-color: $c-navy;

				&:disabled {
					background-color: $c-lg-gray;
				}
			}
		}

		.review {
			width: 50%;
			padding: 10px;
			display: flex;
			flex-direction: column;

			&__wrapper {
				width: 100%;
				display: flex;
				justify-content: space-between;

				.input-wrapper {
					width: 48% !important;
				}
			}

			.input-content {
				display: flex;
				align-items: center;
				justify-content: space-between;
			}

			.textarea-container {
				width: 100% !important;

				textarea {
					outline: none;
					min-height: 200px;
				}
			}

			.input-container {
				width: 48%;

				span {
					min-height: 16px;
					color: $c-black;
					font-family: $gotham-weight-200;
				}
				input {
					border: 0;
					width: 100%;
					outline: none;
					font-family: "gotham-book";
					background-color: transparent;

					&:disabled {
						background-color: #e6e6e6;
					}
				}

				textarea {
					border: 0;
					width: 100%;
				}
			}

			.input-wrapper {
				width: 100%;
				min-height: 55px;
				padding: 10px;
				margin: 10px 0;
				border-radius: 8px;
				border: #c9d2de 1px solid;
				background-color: $c-white;
				display: flex;
				align-items: center;
				justify-content: center;

				&--disabled {
					background-color: #e6e6e6;
				}

				&__text {
					width: 100%;
					margin: 0;
					text-align: left;
				}

				.select-field {
					width: 100%;
					text-align: left;
					border: 0;
					color: #999999;
					font-size: 18px;
					outline: none;
					font-family: $gotham-weight-100;
				}
			}

			.assigned {
				color: $c-navy;
				font-size: $text-size-l;
				font-family: "gotham-book";
			}

			.reviewing {
				font-family: "gotham-book";
			}

			.largeText {
				color: $c-navy;
				white-space: nowrap;
				font-size: $text-size-xl;
				font-family: "gotham-book";
				display: flex;
				align-items: center;
				justify-content: center;
			}

			hr {
				border-top: 3px solid #c9d2de;
			}

			.button-container {
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: flex-end;

				button {
					width: 200px;
					margin: 0 0 0 10px;
				}
			}
		}

		.loan-application-view {
			&__score-button-row {
				border-top-left-radius: 8px;
				border-top-right-radius: 8px;
				overflow: hidden;
			}

			&__score {
				&-wrapper {
					width: 50%;
					margin: 40px 10px 40px 10px;
					border-radius: 10px;
				}
				&-button-row {
					display: flex;
					flex-direction: row;
					height: 120px;
					border-top-left-radius: 10px;
					border-top-right-radius: 10px;
				}
				&-button {
					padding: 20px;
					background-color: $c-white;
					flex: 1;
					display: flex;
					cursor: pointer;

					&--active {
						background-color: $c-dark-navy !important;

						.loan-application-view__score-label-title {
							color: $c-white;
						}

						.loan-application-view__score-label-subtitle {
							color: $c-white;
						}

						.loan-application-view__score-point {
							color: $c-white;
						}
					}
				}

				&-label-column {
					display: flex;
					flex-direction: column;
					justify-content: center;
					flex: 3;
					padding-left: 10px;
				}

				&-label-title {
					color: $c-black;
					font-size: $text-size-l;
					font-family: $gotham-weight-200;
				}

				&-label-subtitle {
					color: $c-black;
					font-size: $text-size-s;
					font-family: $gotham-weight-100;
				}

				&-label-text {
					&--active {
						color: white;
					}
				}

				&-graph {
					position: relative;

					&-wrapper {
						flex: 1;
						display: flex;
						align-items: center;
						justify-content: center;
					}
				}

				&-point {
					position: absolute;
					height: 100%;
					width: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
					font-family: $gotham-weight-300;
					color: $c-black;
					left: 0;
					right: 0;
					margin-left: auto;
					margin-right: auto;
					text-align: center;
				}

				&-content {
					background-color: $c-white;
					margin-top: -10px;
					border-bottom-left-radius: 10px;
					border-bottom-right-radius: 10px;
					&-wrapper {
						height: 460px;
						padding: 30px;
						border-bottom-left-radius: 10px;
						border-bottom-right-radius: 10px;
					}

					&-row {
						display: flex;
						flex-direction: row;
						justify-content: space-between;
						margin-bottom: 30px;
						color: $c-lg-gray;
					}

					&-column {
						&-1 {
							flex: 2;
							font-size: $text-size-m;
							font-family: $gotham-weight-200;
						}
						&-2 {
							flex: 2;
							font-size: $text-size-m;
							font-family: $gotham-weight-100;
						}
						&-3 {
							flex: 1;
							text-align: right;
							font-size: $text-size-m;
							font-family: $gotham-weight-100;
						}
					}
				}

				&-body {
					margin-bottom: 20px;
					display: flex;
					flex-direction: column;
					&:last-child {
						margin-bottom: none;
					}
					&-title {
						color: $c-lg-gray;
						font-family: $gotham-weight-400;
						font-size: $text-size-l;
					}
					&-detail {
						color: $c-lg-gray;
						font-family: $gotham-weight-200;
						font-size: 20px;
					}
				}

				&-ctos-report {
					color: #437fec;
					font-size: 20px;
					font-family: $gotham-weight-200;
					text-decoration: none;
				}
			}

			&__video {
				&-wrapper {
					margin-top: 25px;
					display: none;
					&--show {
						display: block;
					}
				}

				&-none {
					font-family: $gotham-weight-400;
				}

				&-tab-row {
					display: flex;
					flex-direction: row;
				}

				&-tab-button {
					flex: 1;
					display: flex;
					align-items: center;
					justify-content: center;
					background-color: #e6e6e6;
					padding: 15px;
					font-family: $gotham-weight-200;
					color: $c-dark-navy;
					border-top-left-radius: 6px;
					border-top-right-radius: 6px;

					&--active {
						color: $c-white;
						background-color: $c-dark-navy;
					}

					&:hover {
						cursor: pointer;
					}
				}

				&-contents {
					background-color: $c-white;
					height: 400px;
					border-bottom: 1px solid #c9d2de;
					border-left: 1px solid #c9d2de;
					border-right: 1px solid #c9d2de;
				}

				&-body {
					&--hide {
						display: none;
					}
					&--show {
						display: flex;
						align-items: center;
						justify-content: center;
						height: 100%;
					}
				}
			}

			&__download-wrapper {
				border: 1px solid $c-table-icon;
				padding: 0px 5px;
				border-radius: 6px;
				margin-right: 10px;
				cursor: pointer;
				color: $c-table-icon;
			}
		}

		.ctosScore {
			grid-area: auto;
			.tab {
				overflow: hidden;
				border: 1px solid #ccc;
				background-color: #f1f1f1;
			}

			/* Style the buttons inside the tab */
			.tab button {
				background-color: inherit;
				float: left;
				border: none;
				outline: none;
				cursor: pointer;
				padding: 14px 16px;
				transition: 0.3s;
				font-size: 17px;
			}

			/* Change background color of buttons on hover */
			.tab button:hover {
				background-color: #ddd;
			}

			/* Create an active/current tablink class */
			.tab button.active {
				background-color: #ccc;
			}

			/* Style the tab content */
			.tabcontent {
				display: none;
				padding: 6px 12px;
				border: 1px solid #ccc;
				border-top: none;
			}
		}

		.wrapper {
			grid-template-columns: 50% auto;
			grid-template-areas: "review" "ctosScore";
		}
	}

	.dropdown-reject {
		margin: 0px;
		padding: 0px;
		height: 50px;
		color: $c-red;
		border: 1px solid $c-red;
		background-color: transparent;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.dropdown-divider-wrapper {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.dropdown-divider {
		border-bottom: 1px solid $c-table-icon;
		width: 80%;
		margin: 0;
		padding: 0;
	}

	.dropdown-selection {
		margin: 0px;
		padding: 0px;
		list-style-type: none;
		padding: 8px 0px;
		cursor: pointer;
		&:hover {
			color: $c-orange;
		}
	}
}

.audit-trail-modal {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	&__content {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 850px;
		height: 550px;
		width: 60% !important;
	}

	&__body {
		width: 100%;
		padding: 20px 30px;
	}

	&__button-wrapper {
		width: 280px;
		height: 50px;
		margin-bottom: 15px;
	}

	&__table-body {
		max-height: 380px;
		overflow-y: scroll;
		overflow-x: hidden;
	}

	&__header-row {
		width: 100%;
		padding: 15px 20px;
		font-size: $text-size-m;
		font-family: $gotham-weight-200;
		display: flex;
		align-items: center;
	}

	&__table-row {
		padding: 15px 20px;
		color: $c-black;
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	&__table-row--gray {
		background-color: $c-light-gray;
	}

	&__column-timestamp {
		width: 270px;
	}

	&__column-details {
		width: 340px;
	}

	&__column-by {
		width: 200px;
	}
}

.pop-up {
	position: fixed;
	z-index: 100;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.05);
	display: flex;
	justify-content: center;
	align-items: center;
}

.pop-up-data {
	position: center;
	width: 52% !important;
	background-color: white;
	border-radius: 30px;
	text-align: center;
	font-family: $gotham-weight-200;
	font-size: 25px;

	padding: 30px 30px 10px 30px;
}

.pop-up-message {
	font-family: $gotham-weight-200;
	font-size: 30px;
	color: $c-navy;
	margin-top: 10px;
	margin-bottom: 10px;
}

.pop-up-button {
	border: none;
	border-radius: 5px;
	background-color: $c-navy;
	color: $c-white;
	padding: 5px;
	width: 250px;
}
.pop-up-close-button {
	border: none;
	border-radius: 5px;
	background-color: $c-red;
	color: $c-white;
	padding: 5px;
	width: 250px;
}
