@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@700&display=swap");
@import "styles/font.scss";
@import "styles/variables.scss";
@import "styles/mixin.scss";

/* Generic */
@import "styles/main.scss";

/* Components */
@import "styles/components/authentication/app-auth-input.scss";
@import "styles/components/authentication/app-auth-layout.scss";
@import "styles/components/app-layout.scss";
@import "styles/components/app-side-nav.scss";
@import "styles/components/app-button.scss";
@import "styles/components/app-checkbox.scss";
@import "styles/components/app-nav.scss";
@import "styles/components/app-link-button.scss";
@import "styles/components/app-react-table.scss";
@import "styles/components/app-layout.scss";
@import "styles/components/app-table-column-header.scss";
@import "styles/components/app-search-bar.scss";
@import "styles/components/app-row-select.scss";
@import "styles/components/app-item-dropdown.scss";
@import "styles/components/app-table-footer.scss";
@import "styles/components/app-success-message.scss";
@import "styles/components/app-breadcrumb.scss";
@import "styles/components/app-table-page.scss";
@import "styles/components/app-input.scss";
@import "styles/components/authentication/app-forgot-password-input.scss";
@import "styles/components/authentication/app-forgot-password-layout.scss";
@import "styles/components/input.scss";
@import "styles/components/app-toast.scss";
@import "styles/components/app-openvidu.scss";
@import "styles/components/app-input-time.scss";
@import "styles/components/app-confirmation-modal.scss";
@import "styles/components/app-custom-toast.scss";

/* Pages */
@import "styles/pages/authentication/login.scss";
@import "styles/pages/authentication/forgot-password.scss";
@import "styles/pages/home-page.scss";
@import "styles/pages/admin-settings/user-management.scss";
@import "styles/pages/admin-settings/update-user-details.scss";
@import "styles/pages/loan-settings.scss";
@import "styles/pages/initial-approval-matrix.scss";
@import "styles/pages/logout.scss";
@import "styles/pages/loan/loan-application-list.scss";
@import "styles/pages/loan/loan-application-view.scss";
@import "styles/pages/admin-settings/calendar-settings.scss";
@import "styles/pages/admin-settings/modal-select-time.scss";
@import "styles/pages/loan/loan-contract-view.scss";
@import "styles/pages/database/merchant-list.scss";
@import "styles/pages/database/merchant-view.scss";
@import "styles/pages/database/merchant-add.scss";
@import "styles/pages/database/merchant-calendar.scss";
@import "styles/pages/database/customer-view.scss";
@import "styles/pages/report/payment.scss";
@import "styles/pages/report/transaction.scss";

* {
	box-sizing: border-box;
}

html {
	padding: 0;
	margin: 0;
	height: 100%;

	body {
		padding: 0;
		margin: 0;
		min-height: 100%;
		white-space: pre-wrap;
		background-color: $c-white;
		font-family: "Roboto", sans-serif;
	}
}
