.page-customer-view {
	height: 100%;
	width: 100%;
	background-color: #ffffff;

	.customer-view {
		width: 100%;
		padding: 0 20px 30px;

		&__header {
		}

		&__tabs {
			width: 100%;
			margin: 15px 0 0;
			display: flex;

			.tablinks {
				padding: 20px;
				color: $c-white;
				font-size: $text-size-m;
				font-family: $gotham-weight-200;
				background-color: #b6d3f6;
				text-align: center;
				white-space: nowrap;
				cursor: pointer;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			.tab-selected {
				color: $c-black;
				background-color: #f6f6f6 !important;
			}

			.tab1 {
				border-top-left-radius: 10px;
			}

			.tab2 {
				border-top-right-radius: 10px;
			}
		}

		&__body {
			padding: 20px;
			border-top-right-radius: 10px;
			border-bottom-left-radius: 10px;
			border-bottom-right-radius: 10px;
			background-color: #f6f6f6;
		}

		&__content {
			width: 100%;
			display: flex;

			&--contracts {
				flex-direction: column;

				.customer-view__wrapper {
					width: 100%;

					.pagination-button {
						margin: 15px 0;

						span {
							color: $c-black;
						}

						button {
							color: $c-black;
						}
					}
				}
			}
		}

		&__wrapper {
			width: 50%;
		}

		&__label {
			color: $c-white;
			font-size: $text-size-l;
			font-family: $gotham-weight-200;
		}

		&__pagination-container {
			padding: 15px 25px;
			border-top-left-radius: 10px;
			border-top-right-radius: 10px;
			display: flex;
			background-color: $c-dark-navy;
		}

		.input-content {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		.input-container {
			width: 100%;
			margin: 0 25px 10px 0;

			span {
				min-height: 16px;
				color: $c-black;
				font-family: $gotham-weight-300;
			}

			input {
				border: 0;
				width: 100%;
				outline: none;
				font-family: "gotham-book";
				background-color: transparent;

				&:disabled {
					background-color: #e6e6e6;
				}
			}
		}

		.input-wrapper {
			width: 100%;
			min-height: 55px;
			padding: 10px;
			margin: 10px 0 0;
			border-radius: 8px;
			border: #c9d2de 1px solid;
			background-color: $c-white;
			display: flex;
			align-items: center;
			justify-content: center;

			&--error {
				border: 1px solid $c-red;
			}

			&--disabled {
				background-color: #e6e6e6;
			}

			&--checkbox {
				border: 0;
				padding: 0;
				background-color: transparent;
				align-items: flex-start;
				justify-content: flex-start;

				input {
					width: auto !important;
				}
			}

			&__text {
				width: 100%;
				margin: 0;
				text-align: left;
			}

			.select-field {
				width: 100%;
				text-align: left;
				border: 0;
				font-size: 18px;
				outline: none;
				font-family: $gotham-weight-100;
			}
		}

		.pagination-button {
			margin-left: auto;
			display: flex;
			align-items: center;
			justify-content: flex-end;

			span {
				color: $c-white;
				font-family: "gotham-light";
			}

			button {
				border: none;
				color: $c-white;
				text-decoration: none;
				font-family: "gotham-light";
				background-color: transparent;
			}
		}
	}
}
