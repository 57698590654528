.page-user-management {
	padding: 20px;

	.fixed-content {
		width: 100%;
		padding: 0 20px 30px;
	}

	.title {
		margin: 0;
		color: $c-navy;
		font-size: $text-size-xxxl;
		font-family: $gotham-weight-100;
	}

	.top-context {
		padding: 20px 0;
		display: flex;
		align-items: center;
		justify-content: space-between;

		&__wrapper {
			display: flex;
			align-items: center;
		}
	}

	.error {
		display: inline-block;
		padding-top: 10px;
	}

	.search-bar {
		width: 100%;
		height: 55px;
		min-width: 350px;
		max-width: 400px;
		padding: 5px;
		margin-left: 20px;
		border-radius: 8px;
		border: 1px solid #c9d2de;

		.app-search-bar {
			height: 100%;
			padding: 0;
			background-color: transparent;
			display: flex;
			align-items: stretch;
			justify-content: space-between;

			&__button {
				position: relative;
				height: auto;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			input {
				height: 100%;
				padding: 0 0 0 10px;
				font-size: $text-size-m;
				font-family: $gotham-weight-100;

				&::placeholder {
					color: #999999;
					font-size: $text-size-m;
					font-family: $gotham-weight-100;
				}
			}
		}
	}

	.search-button {
		min-width: 200px;
		height: auto;
		margin-left: 20px;

		.app-button__button {
			color: $c-navy;
			border: 1px solid $c-navy;
			background-color: $c-white;
		}
	}

	.user-management {
		&__form {
			padding: 20px;
			background-color: #f6f6f6;
		}

		&__title {
			color: $c-navy;
			font-size: $text-size-l;
		}

		&__label {
			margin: 0 0 5px;
			color: $c-black;
			font-size: $text-size-body;
			font-family: $gotham-weight-300;
		}

		&__note {
			min-height: 16px;
			margin: 5px 0;
			color: $c-black;
			font-size: $text-size-s;
			font-family: $gotham-weight-100;
		}

		&__error {
			min-height: 16px;
			margin: 5px 0;
			color: $c-red;
			font-size: $text-size-s;
			font-family: $gotham-weight-100;
		}

		&__input {
			width: 100%;
			padding-right: 15px;
		}

		&__field {
			width: 100%;
			max-width: 320px;
			max-height: 55px;
			padding: 15px 20px;
			text-align: left;
			font-size: $text-size-m;
			background-color: $c-white;
			border: 1px solid #c9d2de;
			border-radius: 8px;
			outline: none;
			display: flex;
			align-items: center;
			justify-content: space-between;

			input,
			select {
				border: 0;
				width: 100%;
				outline: none;
			}
		}

		&__checkbox {
			margin: 15px 15px 15px 0;
			display: flex;
			align-items: center;
		}

		&__text {
			margin: 0;
			color: lighten($c-black, 20%);
			font-size: $text-size-body;
			font-family: $gotham-weight-200;
		}

		&__input-wrapper {
			width: 55%;
			display: flex;
			align-items: center;
		}
	}

	.table-layout {
		overflow-y: auto;
		height: 500px;
		border-radius: 15px;
		width: 100%;
	}
	.table-layout::-webkit-scrollbar {
		display: none;
	}
	table {
		width: 100%;
		padding: 5px 15px 0px 15px;
		border-collapse: collapse;
	}
	thead th {
		background-color: #f6f6f6;
		position: sticky;
		top: 0;
	}
	table.center {
		margin-left: auto;
		margin-right: auto;
		th {
			text-decoration: underline;
			font-family: "gotham-bold";
		}
		td {
			font-family: "gotham-book";
		}
	}
	tbody tr:nth-child(odd) {
		background-color: #dddddd;
	}
	tr:nth-child(even) {
		background-color: #f6f6f6;
	}
	th,
	td {
		text-align: center;
		padding: 10px 0px 10px 0px;
		font-size: 18px;
	}
	.text-field {
		font-size: 25px;
		font-family: "gotham-bold";
		align-self: flex-end;
	}
	.top-padding {
		div {
			padding-top: 10px;
		}
	}
	.alignBottom {
		align-self: flex-end;
	}
	.input-field {
		padding: 40px;
		background-color: #f6f6f6;
		margin: 30px 50px 30px 50px;
	}
	.text-field {
		font-size: 25px;
		font-family: "gotham-bold";
	}

	.pagination-button {
		display: flex;
		align-items: center;
		justify-content: flex-end;

		span {
			font-family: "gotham-light";
		}

		button {
			border: none;
			color: #999999;
			text-decoration: none;
			font-family: "gotham-light";
			background-color: transparent;
		}
	}
}
