.nav-bar {
	width: 100%;
	height: 95px;
	padding: 0 40px 0 40px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: $c-dark-navy;
	color: $c-lynx-white;

	&__logo {
		height: 75px;
		padding: 10px;
	}

	&__title {
		font-family: $avenir-next-weight-700;
		font-size: $text-size-xl;
	}

	&__contents {
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	&__icon-wrapper {
		position: relative;
	}

	&__profile-picture-img {
		height: 44px;
		width: 44px;
		object-fit: cover;
		border-radius: 999px;
		border: 1px solid $c-weathered-stone;
	}

	&__username {
		font-family: $avenir-next-weight-600;
		font-size: $text-size-body;
		margin-left: 20px;
	}

	&--green-dot {
		height: 9px;
		width: 9px;
		border-radius: 999px;
		background-color: $c-green;
		position: absolute;
		right: 2px;
		top: 2px;
	}

	&__divider {
		border-right: 1px solid $c-dark-gray;
		height: 30px;
		margin-left: 30px;
	}

	&__icon {
		height: 40px;
		object-fit: contain;
		// margin-left: 30px;

		&:hover {
			cursor: pointer;
		}
	}

	&--red-dot {
		height: 6px;
		width: 6px;
		border-radius: 999px;
		background-color: $c-red;
		position: absolute;
		right: 1px;
		top: 2px;
	}

	.dropdown {
		position: relative;
		padding: 0 10px;
	}

	.dropSpan {
		cursor: pointer;
		color: $c-white;
		font-size: $text-size-body;
		font-family: "gotham-book";
		text-decoration: none;
		white-space: nowrap;
	}

	.selected {
		padding: 8px 15px;
		border-radius: 20px;
		color: $c-dark-navy;
		background-color: #ffffff;
	}

	.dropdown-content {
		padding: 10px;
		display: none;
		position: absolute;
		background-color: #f1f1f1;
		font-size: 18px;
		min-width: 160px;
		box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
		z-index: 1;
		text-align: center;
		border-radius: 15px;
		left: 50% !important;
		right: auto !important;
		transform: translate(-50%, 0) !important;

		span {
			padding: 10px 0;
			font-size: 20px;
			color: $c-dark-navy;
			font-family: "gotham-medium";
			border-bottom: 2px solid #9d9d9c;
			display: block;
			white-space: nowrap;
		}

		a {
			&:hover {
				color: $c-dark-navy;
			}
		}
	}

	.dropdown-content a {
		color: #9d9d9c;
		padding: 10px;
		text-decoration: none;
		display: block;
	}

	.dropdown-content a:hover {
		background-color: #f1f1f1;
		border-radius: 15px;
	}

	.dropdown:hover .dropdown-content {
		display: block;
	}
}
