.page-profile {
	padding: 20px;

	.profile {
		//
		&__title {
			margin: 0;
			color: $c-navy;
			font-size: $text-size-xxxl;
			font-family: $gotham-weight-100;
		}

		&__header {
			display: flex;
			align-items: center;
			justify-content: space-between;

			.app-button__button {
				width: 200px;
				height: 55px;
				margin-left: 15px;
				color: $c-navy;
				border: 1px solid $c-navy;
				background-color: $c-white;
				font-family: $gotham-weight-200;
			}
		}

		&__actions {
			display: flex;
			align-items: center;
		}

		&__cancel-button {
			color: $c-red !important;
			border: 1px solid $c-red !important;
		}

		&__body {
			padding: 20px;
			margin-top: 25px;
			border-radius: 10px;
			background-color: #f6f6f6;
			display: flex;
		}

		&__content {
			width: 50%;
			margin: 0 20px 0 0;
		}

		&__input-container {
			margin-bottom: 10px;

			label {
				color: $c-black;
				font-family: $gotham-weight-300;
			}
		}

		&__input {
			width: 100%;
			min-height: 55px;
			padding: 10px;
			margin: 10px 0 0;
			border-radius: 8px;
			border: #c9d2de 1px solid;
			background-color: $c-white;
			display: flex;
			align-items: center;
			justify-content: center;

			&--checkbox {
				border: 0;
				background-color: transparent;
				justify-content: flex-start;
			}

			&--disabled {
				background-color: #e6e6e6;
			}

			input,
			select {
				width: 100%;
				border: 0;
				text-align: left;
				background-color: transparent;
				outline: none;
			}
		}

		&__input-icon {
		}

		&__reset-password-button {
			margin-left: 15px;
			color: $c-navy;
			padding: 5px 15px;
			border-radius: 8px;
			white-space: nowrap;
			border: 1px solid $c-navy;
			background-color: $c-white;
		}

		&__checkbox {
			margin-right: 35px;
			display: flex;
			align-items: center;
			justify-content: center;

			.radio-check {
				transform: scale(2);
			}

			label {
				margin-left: 10px;
			}

			input {
				width: auto;
			}
		}

		&__signature {
			p {
				margin: 0;
				color: $c-black;
				font-family: $gotham-weight-300;
			}
			img {
				width: 100%;
				height: 200px;
				margin-top: 10px;
			}

			button {
				width: 150px;
				height: 55px;
				margin-top: 20px;
				color: $c-navy;
				border-radius: 8px;
				border: 1px solid $c-navy;
				background-color: $c-white;
			}
		}
	}
}

.pop-up-signature {
	border-radius: 10px !important;

	&__button-container {
		margin-top: 20px;
	}

	&__button {
		width: 150px;
		padding: 10px 15px;
		margin: 0 10px;
		border: 0;
		color: $c-white;
		font-size: $text-size-body;
		border-radius: 8px;
		background-color: $c-navy;
		font-family: $gotham-weight-200;
	}
}
