.page-loan-application-list {
	padding: 0 20px 30px;
	background-color: #ffffff;

	.fixed-content {
	}

	.top-context {
		padding: 20px 0;
		display: flex;
		align-items: center;
		justify-content: space-between;

		&__wrapper {
			display: flex;
			align-items: center;
		}
	}

	.title {
		font-size: 48px;
		color: $c-navy;
		font-family: "gotham-light";
	}

	.search-bar {
		width: 100%;
		height: 55px;
		min-width: 350px;
		max-width: 400px;
		padding: 5px;
		margin-left: 20px;
		border-radius: 8px;
		border: 1px solid #c9d2de;

		.app-search-bar {
			height: 100%;
			padding: 0;
			background-color: transparent;
			display: flex;
			align-items: stretch;
			justify-content: space-between;

			&__button {
				position: relative;
				height: auto;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			input {
				height: 100%;
				padding: 0 0 0 10px;
				font-size: $text-size-m;
				font-family: $gotham-weight-100;

				&::placeholder {
					color: #999999;
					font-size: $text-size-m;
					font-family: $gotham-weight-100;
				}
			}
		}
	}

	.search-button {
		width: 200px;
		height: auto;
		margin-left: 20px;

		.app-button__button {
			color: $c-navy;
			border: 1px solid $c-navy;
			background-color: $c-white;
		}
	}

	.input-container {
		margin: 15px 0 25px;
		display: flex;
		flex-wrap: wrap;
		align-items: stretch;

		.input-wrapper {
			width: 100%;
			height: 55px;
			max-width: 250px;
			padding: 10px;
			margin-right: 20px;
			border-radius: 8px;
			border: #c9d2de 1px solid;
			display: flex;
			align-items: center;
			justify-content: center;

			.input-field {
				width: 100%;
				text-align: left;
				border: 0;
				color: #999999;
				font-size: 18px;
				outline: none;
				font-family: $gotham-weight-100;
			}
		}

		.custom-divider {
			padding: 0 15px;
			color: $c-black;
			font-family: $gotham-weight-100;
			font-size: $text-size-l;
			border-left: 1px solid #e6e6e6;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	.pagination-button {
		margin-left: auto;
		display: flex;
		align-items: center;
		justify-content: center;

		span {
			font-family: "gotham-light";
		}

		button {
			border: none;
			color: #999999;
			text-decoration: none;
			font-family: "gotham-light";
			background-color: transparent;
		}
	}

	&__react-table-wrapper {
		.react-table {
			&__icon {
				position: absolute;
				top: 9px;
				left: 5px;
				width: 17px;
				height: 17px;
				border: solid $c-table-icon;
				border-width: 5px 0px 0px 5px;
				-webkit-transform: rotate(45deg);
				-ms-transform: rotate(45deg);
				transform: rotate(45deg);
			}
			&__icon-wrapper {
				position: relative;
				border: 3.5px solid $c-table-icon;
				border-radius: 9px;
				margin-left: 8px;
				color: $c-table-icon;
				height: 35px;
				width: 35px;
				align-items: center;
				justify-content: center;
				display: none;

				&-down {
					-webkit-transform: rotate(180deg);
					-ms-transform: rotate(180deg);
					transform: rotate(180deg);
				}

				// &--show {
				// 	display: inline-block;
				// }
			}

			&__header {
				display: flex;
				align-items: center;

				&-title {
					text-decoration: underline;
				}

				&:hover {
					cursor: pointer;
				}
			}

			&__search-button {
				border: none;
				color: $c-white;
				width: 60px;
				height: 30px;
				background-color: $c-navy;
				border-radius: 10px;
			}

			&__search-button-disable {
				border: none;
				color: $c-white;
				width: 60px;
				height: 30px;
				background-color: $c-light-gray;
				border-radius: 10px;
				cursor: default;
			}
		}
	}
}
