.page-loan-settings {
	background-color: white;

	.loan-settings {
		display: flex;

		&__content {
			width: 50%;
			min-height: 100vh;

			&--bg {
				background-size: cover;
				background-color: right top;
				background-repeat: no-repeat;
				background-image: url("../../assets/images/pages/loan-settings.jpg");
			}
		}

		&__title {
			padding: 20px;
			color: $c-white;
			font-size: $text-size-xxxl;
			font-family: $gotham-weight-100;
			text-align: right;
			display: flex;
			justify-content: flex-end;
		}

		&__calculator {
			margin: 60px;
			padding: 20px;
			border-radius: 10px;
			background-color: #f6f6f6;
		}

		&__wrapper {
			margin: 15px 0;

			&--row {
				display: flex;
				justify-content: space-between;
			}
		}

		&__input-container {
			width: 50%;
		}

		&__input-wrapper {
			display: flex;
			align-items: center;
		}

		&__label {
			margin: 0;
			color: $c-black;
			font-family: $gotham-weight-300;
		}

		&__text {
			min-width: 24px;
			margin: 0 10px;
			color: $c-black;
			font-family: $gotham-weight-200;
		}

		&__note {
			width: 100%;
			display: block;
			color: $c-black;
			font-size: $text-size-xs;
			font-family: $gotham-weight-100 !important;
		}

		&__input {
			width: 100%;
			padding: 15px;
			border-radius: 8px;
			border: 1px solid #c9d2de;
			background-color: $c-white;
			font-family: $gotham-weight-200;
			display: flex;
			align-items: center;
			flex-direction: column;

			span {
				margin-right: 5px;
				color: $c-black;
				font-family: $gotham-weight-300;
			}
		}

		&__field {
			width: 100%;
			display: flex;
			align-items: center;

			select,
			input {
				width: 100%;
				border: 0;
				text-align: left;
			}
		}

		&__button-container {
			display: flex;
			justify-content: flex-end;
		}

		&__button {
			width: 150px;
			margin: 10px;
			padding: 10px 20px;
			border: 0;
			color: $c-white;
			border-radius: 8px;
			background-color: $c-navy;
			font-family: $gotham-weight-200;

			&--cancel {
				background-color: $c-red;
			}
		}
	}
}
