.transaction-page {
	padding: 20px;
	background-color: #ffffff;

	.top-context {
		padding: 20px 0;
		display: flex;
		align-items: center;
		justify-content: space-between;

		&__wrapper {
			display: flex;
			align-items: center;
		}
	}

	.top-content {
		.tabs {
			display: flex;

			.tablinks {
				padding: 15px 20px;
				color: #ecf5ff;
				text-align: center;
				font-size: $text-size-m;
				font-family: $gotham-weight-300;
				cursor: pointer;
			}

			.tab-selected {
				color: $c-black;
				background-color: #f6f6f6 !important;
			}
			.tab1 {
				border-top-left-radius: 8px;
				background-color: #b6d3f6;
			}
			.tab2 {
				background-color: #b6d3f6;
			}
			.tab3 {
				border-top-right-radius: 8px;
				background-color: #b6d3f6;
			}
		}

		.input-container {
			margin: 15px 0 25px;
			display: flex;
			flex-wrap: wrap;
			align-items: stretch;

			.input-wrapper {
				width: 100%;
				height: 55px;
				max-width: 250px;
				padding: 10px;
				margin-right: 20px;
				border-radius: 8px;
				border: #c9d2de 1px solid;
				background-color: $c-white;
				display: flex;
				align-items: center;
				justify-content: center;

				.input-field {
					width: 100%;
					text-align: left;
					border: 0;
					color: #999999;
					font-size: 18px;
					outline: none;
					font-family: $gotham-weight-100;
				}
			}

			.custom-divider {
				padding: 0 15px;
				color: $c-black;
				font-family: $gotham-weight-100;
				font-size: $text-size-l;
				border-left: 1px solid #e6e6e6;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}

		.side-tabs {
			padding: 20px;
			background-color: #f6f6f6;
			border-top-right-radius: 10px;
			display: flex;
			flex-direction: column;

			.side-tabs-wrapper {
				display: flex;
			}

			.search-bar {
				width: 100%;
				height: 55px;
				min-width: 350px;
				max-width: 400px;
				padding: 5px;
				border-radius: 8px;
				border: 1px solid #c9d2de;
				background-color: $c-white;

				.app-search-bar {
					height: 100%;
					padding: 0;
					background-color: transparent;
					display: flex;
					align-items: stretch;
					justify-content: space-between;

					&__button {
						position: relative;
						height: auto;
						display: flex;
						align-items: center;
						justify-content: center;
					}

					input {
						height: 100%;
						padding: 0 0 0 10px;
						font-size: $text-size-m;
						font-family: $gotham-weight-100;

						&::placeholder {
							color: #999999;
							font-size: $text-size-m;
							font-family: $gotham-weight-100;
						}
					}
				}
			}

			.search-button {
				width: 200px;
				height: auto;
				margin-left: 20px;

				.app-button__button {
					color: $c-navy;
					border: 1px solid $c-navy;
					background-color: $c-white;
				}
			}
		}
	}

	.content-color {
		background-color: #f6f6f6;
	}

	.table-content {
		width: 100%;
		overflow-x: scroll;
		background-color: #f6f6f6;
	}

	.pop-up {
		position: fixed;
		z-index: 100;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.01);
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.pop-up-data {
		padding: 25px;
		position: center;
		border-radius: 10px;
		font-size: 25px;
		text-align: center;
		background-color: white;
		font-family: "gotham-bold";

		.input-wrapper {
			display: flex;
			align-items: center;

			input {
				width: 100%;
				padding: 10px 15px;
				border-radius: 8px;
				outline: none;
				font-size: $text-size-body;
				border: 1px solid #c9d2de;
				font-family: "gotham-book";
			}
		}

		.upload-icon {
			width: 30px;
			height: 30px;
		}

		.delete-button {
			border: 0;
			background-color: transparent;
		}
	}

	.pop-up-message {
		margin: 15px 0;
		font-size: 30px;
		color: $c-navy;
		font-family: "gotham-book";

		textarea {
			width: 100%;
			min-height: 200px;
			border-radius: 8px;
			font-size: $text-size-body;
			border: 1px solid #c9d2de;
		}
	}

	.pop-up-button {
		margin-top: 25px;
		border: none;
		width: 250px;
		color: $c-white;
		border-radius: 8px;
		padding: 15px 20px;
		font-size: $text-size-body;
		background-color: $c-navy;
		font-family: "gotham-book";
	}

	.pop-up-close-button {
		border: none;
		border-radius: 5px;
		background-color: $c-red;
		color: $c-white;
		padding: 5px;
		width: 250px;
	}

	.pagination-button {
		padding-top: 20px;
		background-color: $c-white;
		display: flex;
		align-items: center;
		justify-content: flex-end;

		span {
			font-family: "gotham-light";
		}

		button {
			border: none;
			color: #999999;
			text-decoration: none;
			font-family: "gotham-light";
			background-color: transparent;
		}
	}

	.column {
		float: left;
		width: 50%;
		padding: 10px;
		color: black;
	}

	/* Clear floats after the columns */
	.row:after {
		display: table;
		clear: both;
	}

	.span-title {
		font-size: $text-size-body;
		font-family: $gotham-weight-300;
	}
	.span-detail {
		font-size: $text-size-body;
		font-family: "gotham-book";
	}

	.table-invoice {
		display: flex;
		justify-content: center;
		font-size: 22px;
		margin-bottom: 15px;

		tbody {
			overflow-y: scroll;
		}
	}

	.tr-head {
		background-color: $c-dark-navy;

		th {
			padding: 15px;
			color: $c-white;
			font-size: $text-size-body;
		}
	}

	.tr-body {
		color: #1d1d1d;
		font-size: large;
		font-family: "gotham-book";

		td {
			font-size: $text-size-body;
		}

		tr:nth-child(even) {
			background-color: $c-light-gray;
			height: 40px;
		}
		tr:nth-child(odd) {
			background-color: $c-white;
			height: 40px;
		}
	}
}
