.app-forgot-password-input {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;

	&__container {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	&__input-wrapper {
		display: flex;
		flex-direction: row;
		flex: 1;
		height: 100%;
		position: relative;
	}

	&__input {
		width: 100%;
		height: 56px;
		font-size: $text-size-body;
		align-items: center;
		border-radius: 4px;
		padding-left: 20px;
		font-family: $avenir-next-weight-400;
		border: 0;

		&:focus {
			outline: none;
		}

		&::placeholder {
			color: $c-placeholder;
			font-family: $avenir-next-weight-300;
		}

		&::-ms-reveal {
			display: none;
		}
	}

	&--error {
		border: 3px solid $c-red !important;

		&::placeholder {
			color: $c-red !important;
		}
	}

	&__password-eye {
		object-fit: contain;
		display: flex;
		position: absolute;
		right: 10px;
		height: 100%;
		align-items: center;
	}

	&__error {
		color: $c-red;
		font-size: $text-size-xs;
		min-height: 20px;
		padding: 0px !important;
		margin: 0px !important;
	}

	&__icon-input {
		background-color: #fff;
		border-radius: 10px;
		display: flex;
		align-items: center;
		justify-content: space-between;

		input {
			background-color: transparent;
			border: 0;
			font-size: inherit;
			flex: 1 0 auto;
			min-width: 100px;
			padding: 0.5em;
			&:focus {
				outline: none;
			}

			&::placeholder {
				color: $c-placeholder;
				font-family: $avenir-next-weight-300;
			}
		}
	}
}
