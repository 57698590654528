.page-report-payment {
	padding: 20px;
	background-color: #ffffff;

	.column {
	}

	.left-side {
		width: 30%;

		.side-menu {
			overflow-y: auto;

			a {
				color: #c6c6c6;
				display: block;
				padding: 12px;
				border-radius: 8px;
				text-decoration: none;
				border-bottom: 1px solid #eaeaea;
				font-family: "gotham-book";
				font-size: $text-size-body;
				cursor: pointer;
			}
			.clicked {
				background-color: #ffffff;
				color: $c-navy;
			}
		}
	}

	.right-side {
		width: 70%;

		.header {
			padding-bottom: 20px;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		.generate-button {
			padding: 15px 20px;
			border: 0;
			color: $c-white;
			border-radius: 8px;
			background-color: $c-navy;
		}

		.title-button {
			border: none;
			border-radius: 8px;
			background-color: #f5903d;
			color: #ffffff;
			font-family: "gotham-bold";
			font-size: $text-size-m;
			height: 50px;
		}

		.label {
			font-size: $text-size-body;
			font-family: "gotham-bold";
		}

		.payment-input {
			width: 100% !important;
			max-width: 320px;
			padding: 10px 15px;
			border-radius: 8px;
			font-size: $text-size-body;
			border: 1px solid #c9d2de;
		}

		.date-select {
			margin-bottom: 15px;
			display: flex;
			flex-direction: column;
		}

		.payment-table {
			overflow-x: scroll;
			overflow-y: hidden;
		}

		.search-button {
			width: 150px;
			padding: 10px 20px;
			border: 0;
			color: $c-white;
			border-radius: 8px;
			font-size: $text-size-body;
			background-color: $c-navy;
		}

		.title-text {
			margin: 0;
			color: $c-navy;
			font-size: $text-size-xl;
			font-family: $gotham-weight-100;
		}

		.subtitle-text {
			font-size: 25px;
			font-family: "gotham-bold";
			color: #1d1d1b;
		}

		.menu1 {
			width: 65%;
			height: 120px;
			float: left;
			font-size: 22px;
		}
		.menu2 {
			width: 35%;
			height: 120px;
			float: left;
			font-size: 22px;
			.generate-btn {
				position: absolute;
				top: 140px;
				right: 100px;
				border: none;
				border-radius: 8px;
				background-color: $c-beige;
				color: $c-white;
			}
		}
		.half-menu {
			width: 48%;
			// height: 100px;
			float: left;
			font-size: 22px;

			.check-void {
				width: 100%;
				margin-bottom: 20px;
				display: flex;
				input {
					width: 100px;
					height: 36px;
					border: 1px solid #d9d9d9;
					border-radius: 4px;
					padding-left: 10px;
				}
			}
		}
		.third-tab {
			font-size: 22px;
			font-family: "gotham-bold";
			height: 630px;
			min-width: 821px;
			padding-left: 25px;
			padding-right: 25px;
			.top-date {
				display: block;
				.split-block {
					width: 49%;
					float: left;
					.span-input {
						width: 120px;
						float: left;
					}
					input {
						font-family: "gotham-book";
						padding-left: 10px;
					}
				}
			}
			.search-button {
				margin-top: 20px;
				padding-right: 20px;
			}
			.filename {
				width: 800px;
				height: 50px;
				color: black;
				background-color: white;
				display: flex;
				justify-content: space-between;
				align-items: center;
			}

			.selected-filename {
				padding-left: 10px;
				padding-right: 10px;
				font-family: "gotham-book";
			}
		}
	}

	/* Remove extra left and right margins, due to padding */
	.row {
		margin: 0 -5px;
	}

	/* Clear floats after the columns */
	.row:after {
		content: "";
		display: table;
		clear: both;
	}

	/* Responsive columns */
	@media screen and (max-width: 1000px) {
		.column {
			// width: 100%;
			display: block;
			margin-bottom: 20px;
		}
		.left-side {
			width: 100%;
			.side-menu {
				overflow-y: auto;
				height: 200px;
			}
		}

		.right-side {
			width: 100%;
		}
	}

	/* Style the counter cards */
	.card {
		//   box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
		padding: 16px 8px 16px 16px;
		text-align: left;
		background-color: #eee;
	}

	.side-menu::-webkit-scrollbar,
	.dashBoardBody::-webkit-scrollbar,
	.customerDetailsBody::-webkit-scrollbar {
		// display: none;
		width: 10px;
	}
	.side-menu::-webkit-scrollbar-track,
	.dashBoardBody::-webkit-scrollbar-track,
	.customerDetailsBody::-webkit-scrollbar-track {
		background: #f1f1f1;
		border-radius: 8px;
	}
	.side-menu::-webkit-scrollbar-thumb,
	.dashBoardBody::-webkit-scrollbar-thumb,
	.customerDetailsBody::-webkit-scrollbar-thumb {
		background: #888;
		border-radius: 8px;
	}

	.display {
		//display: contents;
		position: relative;
	}

	.hide {
		display: none;
	}

	.pop-up-payment {
		position: fixed;
		z-index: 100;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.01);
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.pop-up-data-pay {
		position: center;
		background-color: white;
		font-size: 25px;
		padding: 30px;
		border-radius: 10px;

		.payment-form {
			display: flex;
			justify-content: space-between;
		}

		.input-wrapper {
			width: 50%;
			padding-right: 20px;
		}

		.label {
			font-size: $text-size-body;
			font-family: "gotham-bold";
		}

		.payment-input {
			width: 100% !important;
			max-width: 320px;
			padding: 10px 15px;
			border-radius: 8px;
			font-size: $text-size-body;
			border: 1px solid #c9d2de;
		}

		.button-container {
			width: 100%;
			margin-top: 25px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	.pop-up-data-report {
		position: center;
		background-color: white;
		border-radius: 30px;
		text-align: center;
		font-family: "gotham-bold";
		font-size: 25px;

		padding: 30px 30px 10px 30px;
	}

	.pop-up-message {
		margin: 10px 0;
		color: $c-navy;
		font-size: $text-size-l;
		font-family: "gotham-bold";
	}

	.pop-up-button {
		border: none;
		color: $c-white;
		padding: 10px 15px;
		border-radius: 8px;
		background-color: $c-navy;
		font-size: $text-size-body;
		font-family: "gotham-book";
	}

	.pop-up-close-button {
		border: none;
		border-radius: 5px;
		background-color: $c-red;
		color: $c-white;
		padding: 5px;
		width: 250px;
	}

	.dateInput-style {
		border-radius: 5px;
		border: 1px solid #d9d9d9;
		padding: 5px;
		font-size: 20px;
	}

	.column-popup {
		width: 50%;
		color: black;
	}

	.span-title {
		font-family: "gotham-bold";
		font-size: $text-size-body;
	}
	.span-detail {
		font-family: "gotham-book";
		font-size: $text-size-body;
	}

	.table-invoice {
		display: flex;
		justify-content: center;
		font-size: 20px;
		margin-bottom: 15px;
		margin-top: 20px;
		th {
			word-break: break-word;
			padding: 10px 0px 10px 20px;
		}
		tbody {
			overflow-y: scroll;
			td {
				// padding: 0px 0px 0px 20px;
				text-align: center;
			}
		}
	}

	.tr-head {
		color: $c-white;
		font-family: "gotham-book";
		font-size: $text-size-body;
		background-color: $c-dark-navy;
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
	}

	.tr-body {
		color: #1d1d1d;
		font-family: "gotham-book";
		font-size: $text-size-s;

		tr:nth-child(even) {
			background-color: $c-light-gray;
		}

		tr:nth-child(odd) {
			background-color: $c-white;
		}

		td {
			padding: 10px;
		}
	}

	.check-box {
		transform: scale(1.5);
		-ms-transform: scale(1.5);
		-webkit-transform: scale(1.5);
		-o-transform: scale(1.5);
		-moz-transform: scale(1.5);
		transform-origin: 0 0;
		-ms-transform-origin: 0 0;
		-webkit-transform-origin: 0 0;
		-o-transform-origin: 0 0;
		-moz-transform-origin: 0 0;
		margin-right: 8px;
	}

	.upload-file-input {
		width: 100%;
		padding: 10px 15px;
		outline: none;
		border-radius: 8px;
		color: $c-dark-gray;
		font-size: $text-size-s;
		border: 1px solid $c-border-gray;
		font-family: $avenir-next-weight-500;

		&::placeholder {
			color: $c-weathered-stone;
		}

		&:focus {
			border: 2px solid $c-cyan;
		}
	}

	.file-input {
		width: 100%;
	}

	.file-input-wrapper {
		display: flex;
		align-items: center;
	}

	.upload-icon {
		width: 35px;
		height: 35px;
		cursor: pointer;
	}

	.upload-file-input-error {
		width: 100%;
		outline: none;
		border-radius: 8px;
		color: $c-dark-gray;
		font-size: $text-size-s;
		padding: 10px 15px;
		border: 1px solid $c-red !important;

		&::placeholder {
			color: $c-weathered-stone;
		}

		&:focus {
			border: 2px solid $c-cyan;
		}
	}

	.button-container {
		margin-top: 20px;

		.pop-up-button {
			margin: 0 10px;
		}
	}
}
