.contract-view {
	height: 100%;
	width: 100%;
	padding: 0 20px;
	background-color: #ffffff;

	.top-context {
		width: 100%;
		padding: 20px 0 0;
		display: flex;
		align-items: center;
		justify-content: space-between;

		.tabs {
			width: 100%;
			margin: 15px 0 0;
			display: flex;

			.tablinks {
				padding: 20px;
				color: $c-white;
				font-size: $text-size-m;
				font-family: $gotham-weight-200;
				text-align: center;
				white-space: nowrap;
				display: flex;
				align-items: center;
				justify-content: center;
				cursor: pointer;
			}

			.tab-selected {
				color: $c-black;
				background-color: #f6f6f6 !important;
			}

			.tab1 {
				border-top-left-radius: 10px;
				background-color: #b6d3f6;
			}
			.tab2 {
				background-color: #b6d3f6;
			}
			.tab3 {
				border-top-right-radius: 10px;
				background-color: #b6d3f6;
			}
		}

		.dropdown {
			position: relative;
			display: inline-block;
		}

		.dropSpan {
			font-size: 25px;
			cursor: pointer;
			font-family: "gotham-medium";
		}

		.dropButton {
			background-color: #f5903d;
			color: white;
			border: none;
			border-radius: 10px;
			width: 190px;
			height: 50px;
			font-size: 16px;
			cursor: pointer;
			font-family: "gotham-medium";
		}

		.dropButtonHover {
			background-color: white;
			color: #f5903d;
			border: none;
			border-radius: 10px;
			width: 190px;
			height: 50px;
			font-size: 16px;
			cursor: pointer;
			font-family: "gotham-medium";
			text-decoration: underline;
		}

		.dropdown-content {
			display: none;
			position: absolute;
			background-color: #f1f1f1;
			font-size: 18px;
			min-width: 160px;
			box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
			z-index: 1;
			text-align: center;
			border-radius: 15px;
			left: 50% !important;
			right: auto !important;
			transform: translate(-50%, 0) !important;
			span {
				border-bottom: 2px solid #9d9d9c;
				padding: 1px 20px;
				color: $c-beige;
				font-family: "gotham-medium";
				font-size: 20px;
			}
		}

		.dropdown-content a {
			color: #9d9d9c;
			padding: 3px 4px;
			text-decoration: none;
			display: block;
		}

		.dropdown-content a:hover {
			background-color: #f1f1f1;
			border-radius: 15px;
		}

		.show {
			display: block;
		}
	}
	.title {
		float: left;
		font-size: 60px;
		color: $c-beige;
		font-family: $gotham-weight-200;
		letter-spacing: 0;
		text-align: left;
	}

	.search-button1 {
		margin-left: auto;

		button {
			padding: 15px 20px;
			color: $c-navy;
			background-color: $c-white;
			border: 1px solid $c-navy;
			font-family: $gotham-weight-200;
			border-radius: 8px;
		}
	}

	.contract-detail {
		padding-bottom: 50px;

		.input-content {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		.textarea-container {
			width: 100% !important;

			textarea {
				outline: none;
				min-height: 200px;
			}
		}

		.input-container {
			width: 48%;

			span {
				display: block;
				min-height: 24px;
				color: $c-black;
				font-family: $gotham-weight-200;
			}
			input {
				border: 0;
				width: 100%;
				outline: none;
				font-family: "gotham-book";
				background-color: transparent;

				&:disabled {
					background-color: #e6e6e6;
				}
			}

			textarea {
				border: 0;
				width: 100%;
			}
		}

		.input-wrapper {
			width: 100%;
			min-height: 55px;
			padding: 10px;
			margin: 10px 0;
			border-radius: 8px;
			border: #c9d2de 1px solid;
			background-color: $c-white;
			display: flex;
			align-items: center;
			justify-content: center;

			&--disabled {
				background-color: #e6e6e6;
			}

			&__text {
				width: 100%;
				margin: 0;
				text-align: left;
			}

			.select-field {
				width: 100%;
				text-align: left;
				border: 0;
				color: #999999;
				font-size: 18px;
				outline: none;
				font-family: $gotham-weight-100;
			}
		}

		.wrapper {
			width: 100%;
			border-radius: 8px;
			background-color: #f6f6f6;
			display: flex;
		}

		.redButton {
			border: none;
			width: 35px;
			height: 35px;
			font-size: 22px;
			color: #ffffff;
			border-radius: 100px;
			font-family: "gotham-bold";
			background-color: #fc1818;
		}
		.review {
			width: 50%;
			padding: 25px 10px 20px 35px;
			.assigned {
				font-family: "gotham-bold";
				color: $c-beige;
				font-size: x-large;
			}
			.reviewing {
				padding-top: 8px;
				font-family: "gotham-book";
				font-size: medium;
				float: right;
			}
			.largeText {
				font-size: 35px;
				color: $c-navy;
				font-family: "gotham-light";
			}
			hr {
				border-top: 3px solid #9d9d9c;
			}
			.content {
				margin-bottom: 20px;
				height: 40px;

				span {
					color: $c-black;
					font-family: $gotham-weight-200;
				}

				input {
					border: 0;
					width: 100%;
					outline: none;
					font-family: "gotham-book";
					background-color: transparent;

					&:disabled {
						background-color: #e6e6e6;
					}
				}

				textarea {
					padding-left: 10px;
					border: none;
				}
			}

			.item1 {
				font-size: $text-size-m;
				font-family: "gotham-light";
			}

			.item2 {
				font-size: $text-size-s;
				font-family: "gotham-light";
			}

			.item3 {
				img {
					width: 50px;
					height: 50px;
					cursor: pointer;
				}
				label {
					width: 50px;
					height: 50px;
					cursor: pointer;
				}
			}

			.item4 {
				img {
					width: 50px;
					height: 50px;
				}
			}

			.defaultPointer {
				filter: alpha(opacity=40);
				opacity: 0.4;
				cursor: not-allowed;
			}

			.pointer {
				cursor: pointer;
			}

			.item5 {
				img {
					width: 50px;
					height: 50px;
				}
			}

			.disable-img {
				filter: alpha(opacity=40);
				opacity: 0.4;
				cursor: not-allowed;
			}

			.grid-container {
				padding: 1px;
				margin-bottom: 20px;
				display: flex;
				align-items: center;
				justify-content: space-between;
			}

			.grid-wrapper {
				&--column {
					display: flex;
				}
			}

			.payment-content {
				margin-bottom: 20px;
				height: 40px;
				span {
					font-family: "gotham-bold";
					color: #1d1d1d;
					font-size: 22px;
					width: 59%;
					float: left;
				}
				input {
					border: none;
					border-radius: 5px;
					height: 46px;
					margin-right: 10px;
					width: 39%;
					float: left;
					padding-left: 20px;
				}
			}
			.bottom-content {
				div {
					margin-bottom: 15px;
				}
				span {
					font-family: "gotham-bold";
					color: #1d1d1d;
					font-size: 18px;
					width: 25%;
				}
				input {
					border-radius: 5px;
					height: 32px;
					margin-right: 10px;
					width: 70%;
					float: right;
				}
				.remark {
					width: 71.3%;
					float: right;
					select {
						width: 88%;
						border-radius: 5px;
						height: 32px;
					}
					textarea {
						border-radius: 5px;
					}
					button {
						background-color: $c-orange;
						color: white;
						width: 10%;
						height: 32px;
						border: none;
						border-radius: 8px;
						margin-right: 10px;
					}
				}
			}
		}
		.ctosScore {
			width: 50%;

			.tab {
				overflow: hidden;
				border: 1px solid #ccc;
				background-color: #f1f1f1;
			}

			/* Style the buttons inside the tab */
			.tab button {
				background-color: inherit;
				float: left;
				border: none;
				outline: none;
				cursor: pointer;
				padding: 14px 16px;
				transition: 0.3s;
				font-size: 17px;
			}

			/* Change background color of buttons on hover */
			.tab button:hover {
				background-color: #ddd;
			}

			/* Create an active/current tablink class */
			.tab button.active {
				background-color: #ccc;
			}

			/* Style the tab content */
			.tabcontent {
				display: none;
				padding: 6px 12px;
				border: 1px solid #ccc;
				border-top: none;
			}
		}

		.select-field {
			margin-top: 8px;
			border: #e3e3e3 1px solid;
			border-radius: 8px;
			width: 250px;
			height: 37px;
			text-align: center;
		}

		.display {
			display: block;
		}

		.hide {
			display: none;
		}

		.loan-application-view {
			&__score-button-row {
				border-top-left-radius: 8px;
				border-top-right-radius: 8px;
				overflow: hidden;
			}

			&__score {
				&-wrapper {
					margin: 40px 10px 40px 10px;
					border-radius: 10px;
				}
				&-button-row {
					display: flex;
					flex-direction: row;
					height: 120px;
					border-top-left-radius: 10px;
					border-top-right-radius: 10px;
				}
				&-button {
					padding: 20px;
					background-color: $c-white;
					flex: 1;
					display: flex;
					cursor: pointer;

					&--active {
						background-color: $c-dark-navy !important;

						.loan-application-view__score-label-title {
							color: $c-white;
						}

						.loan-application-view__score-label-subtitle {
							color: $c-white;
						}

						.loan-application-view__score-point {
							color: $c-white;
						}
					}
				}

				&-label-column {
					display: flex;
					flex-direction: column;
					justify-content: center;
					flex: 3;
					padding-left: 10px;
				}

				&-label-title {
					color: $c-black;
					font-size: $text-size-l;
					font-family: $gotham-weight-200;
				}

				&-label-subtitle {
					color: $c-black;
					font-size: $text-size-s;
					font-family: $gotham-weight-100;
				}

				&-label-text {
					&--active {
						color: white;
					}
				}

				&-graph {
					position: relative;

					&-wrapper {
						flex: 1;
						display: flex;
						align-items: center;
						justify-content: center;
					}
				}

				&-point {
					position: absolute;
					height: 100%;
					width: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
					font-family: $gotham-weight-300;
					color: $c-black;
					left: 0;
					right: 0;
					margin-left: auto;
					margin-right: auto;
					text-align: center;
				}

				&-content {
					background-color: $c-white;
					margin-top: -10px;
					border-bottom-left-radius: 10px;
					border-bottom-right-radius: 10px;
					&-wrapper {
						height: 460px;
						padding: 30px;
						border-bottom-left-radius: 10px;
						border-bottom-right-radius: 10px;
					}

					&-row {
						display: flex;
						flex-direction: row;
						justify-content: space-between;
						margin-bottom: 30px;
						color: $c-lg-gray;
					}

					&-column {
						&-1 {
							flex: 2;
							font-size: $text-size-m;
							font-family: $gotham-weight-200;
						}
						&-2 {
							flex: 2;
							font-size: $text-size-m;
							font-family: $gotham-weight-100;
						}
						&-3 {
							flex: 1;
							text-align: right;
							font-size: $text-size-m;
							font-family: $gotham-weight-100;
						}
					}
				}

				&-body {
					margin-bottom: 20px;
					display: flex;
					flex-direction: column;
					&:last-child {
						margin-bottom: none;
					}
					&-title {
						color: $c-lg-gray;
						font-family: $gotham-weight-400;
						font-size: $text-size-l;
					}
					&-detail {
						color: $c-lg-gray;
						font-family: $gotham-weight-200;
						font-size: 20px;
					}
				}

				&-ctos-report {
					color: #437fec;
					font-size: 20px;
					font-family: $gotham-weight-200;
					text-decoration: none;
				}
			}

			&__video {
				&-wrapper {
					margin-top: 25px;
					display: none;
					&--show {
						display: block;
					}
				}

				&-none {
					font-family: $gotham-weight-400;
				}

				&-tab-row {
					display: flex;
					flex-direction: row;
				}

				&-tab-button {
					flex: 1;
					display: flex;
					align-items: center;
					justify-content: center;
					background-color: #e6e6e6;
					padding: 15px;
					font-family: $gotham-weight-200;
					color: $c-dark-navy;
					border-top-left-radius: 6px;
					border-top-right-radius: 6px;

					&--active {
						color: $c-white;
						background-color: $c-dark-navy;
					}

					&:hover {
						cursor: pointer;
					}
				}

				&-contents {
					background-color: $c-white;
					border-bottom: 1px solid #c9d2de;
					border-left: 1px solid #c9d2de;
					border-right: 1px solid #c9d2de;
					overflow-y: scroll;
				}

				&-body {
					&--hide {
						display: none;
					}
					&--show {
						display: flex;
						align-items: center;
						justify-content: center;
						height: 100%;
					}
				}
			}

			&__download-wrapper {
				border: 1px solid $c-table-icon;
				padding: 0px 5px;
				border-radius: 6px;
				margin-right: 10px;
				cursor: pointer;
				color: $c-table-icon;
			}
		}
	}

	.pagination-button {
		margin-left: auto;
		display: flex;
		align-items: center;
		justify-content: flex-end;

		span {
			font-family: "gotham-light";
		}

		button {
			border: none;
			color: #999999;
			text-decoration: none;
			font-family: "gotham-light";
			background-color: transparent;
		}
	}

	.table-border {
		padding: 20px;
		border-radius: 10px;
	}
}
