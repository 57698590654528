.merchant-view {
	height: 100%;
	width: 100%;
	background-color: #ffffff;

	.top-context {
		padding: 20px 20px 0;
		display: flex;
		align-items: center;
		justify-content: space-between;

		.tabs {
			width: 100%;
			margin: 15px 0 0;
			display: flex;
			align-items: center;

			.tablinks {
				padding: 20px;
				color: $c-white;
				font-size: $text-size-m;
				font-family: $gotham-weight-200;
				background-color: #b6d3f6;
				text-align: center;
				white-space: nowrap;
				cursor: pointer;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			.tabs-action {
				margin-left: auto;
				display: flex;

				.search-button {
					margin-left: 15px;
				}
			}

			.tab-selected {
				color: $c-black;
				background-color: #f6f6f6 !important;
			}

			.tab1 {
				border-top-left-radius: 10px;
			}

			.tab3 {
				border-top-right-radius: 10px;
			}
		}

		.dropdown {
			position: relative;
			display: inline-block;
		}

		.dropSpan {
			font-size: 25px;
			cursor: pointer;
			font-family: "gotham-medium";
		}

		.dropButton {
			background-color: #f5903d;
			color: white;
			border: none;
			border-radius: 10px;
			width: 190px;
			height: 50px;
			font-size: 16px;
			cursor: pointer;
			font-family: "gotham-medium";
		}

		.dropButtonHover {
			background-color: white;
			color: #f5903d;
			border: none;
			border-radius: 10px;
			width: 190px;
			height: 50px;
			font-size: 16px;
			cursor: pointer;
			font-family: "gotham-medium";
			text-decoration: underline;
		}

		.dropdown-content {
			display: none;
			position: absolute;
			background-color: #f1f1f1;
			font-size: 18px;
			min-width: 160px;
			box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
			z-index: 1;
			text-align: center;
			border-radius: 15px;
			left: 50% !important;
			right: auto !important;
			transform: translate(-50%, 0) !important;
			span {
				border-bottom: 2px solid #9d9d9c;
				padding: 1px 20px;
				color: $c-beige;
				font-family: "gotham-medium";
				font-size: 20px;
			}
		}

		.dropdown-content a {
			color: #9d9d9c;
			padding: 3px 4px;
			text-decoration: none;
			display: block;
		}

		.dropdown-content a:hover {
			background-color: #f1f1f1;
			border-radius: 15px;
		}

		.show {
			display: block;
		}
	}

	.search-bar {
		width: 100%;
		height: 55px;
		min-width: 350px;
		max-width: 400px;
		padding: 5px;
		margin-left: 20px;
		border-radius: 8px;
		border: 1px solid #c9d2de;

		.app-search-bar {
			height: 100%;
			padding: 0;
			background-color: transparent;
			display: flex;
			align-items: stretch;
			justify-content: space-between;

			&__button {
				position: relative;
				height: auto;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			input {
				height: 100%;
				padding: 0 0 0 10px;
				font-size: $text-size-m;
				font-family: $gotham-weight-100;

				&::placeholder {
					color: #999999;
					font-size: $text-size-m;
					font-family: $gotham-weight-100;
				}
			}
		}
	}

	.search-button {
		width: 200px;
		height: 50px;

		&--cancel {
			.app-button__button {
				color: $c-navy;
				background-color: $c-white;
				border: 1px solid $c-navy;
			}
		}
	}

	.check-box {
		zoom: 1.5;
		transform: scale(1.5);
		-ms-transform: scale(1.5);
		-webkit-transform: scale(1.5);
		-o-transform: scale(1.5);
		-moz-transform: scale(1.5);
		transform-origin: 0 0;
		-ms-transform-origin: 0 0;
		-webkit-transform-origin: 0 0;
		-o-transform-origin: 0 0;
		-moz-transform-origin: 0 0;
	}

	.merchant-detail {
		&__wrapper {
			margin: 0 20px;
			display: flex;
			background-color: #f6f6f6;
			border-radius: 10px;
		}

		&__content {
			padding: 0 0 50px;

			.qrcode {
				width: 50%;
				display: flex;
				align-items: flex-start;
				justify-content: center;

				&__container {
					margin-top: 15%;
					display: flex;
					align-items: center;
					flex-direction: column;
					justify-content: center;

					img {
						width: 250px;
						height: 250px;
					}

					.save-pdf {
						width: 100%;
						max-width: 250px;
						padding: 15px 20px;
						color: $c-white;
						background-color: $c-navy;
						border: 0;
					}
				}
			}

			.input-form {
				width: 50%;
				padding: 20px;
				border-top-right-radius: 10px;
				border-bottom-right-radius: 10px;
				border-bottom-right-radius: 10px;
				background-color: #f6f6f6;
			}

			.input-content {
				display: flex;
				align-items: center;
				justify-content: space-between;
			}

			.input-container {
				width: 100%;
				margin: 0 25px 10px 0;

				span {
					min-height: 16px;
					color: $c-black;
					font-family: $gotham-weight-300;
				}

				input {
					border: 0;
					width: 100%;
					outline: none;
					font-family: "gotham-book";
					background-color: transparent;

					&:disabled {
						background-color: #e6e6e6;
					}
				}

				textarea {
					border: 0;
					min-height: 200px;
					outline: none;
				}

				select {
					width: 100%;
					outline: none;
					text-align: left;
				}
			}

			.input-wrapper {
				width: 100%;
				min-height: 55px;
				padding: 10px;
				margin: 10px 0 0;
				border-radius: 8px;
				border: #c9d2de 1px solid;
				background-color: $c-white;
				display: flex;
				align-items: center;
				justify-content: center;

				&--error {
					border: 1px solid $c-red;
				}

				&--disabled {
					background-color: #e6e6e6;
				}

				&--checkbox {
					border: 0;
					padding: 0;
					background-color: transparent;
					align-items: flex-start;
					justify-content: flex-start;

					input {
						width: auto !important;
					}
				}

				&__text {
					width: 100%;
					margin: 0;
					text-align: left;
				}

				&__terminate {
					border: 0;
					background-color: transparent;
					justify-content: flex-start;
				}

				&__download {
					cursor: pointer;
					justify-content: space-between;

					p {
						margin: 0 15px 0 0;
						font-family: $gotham-weight-200;
					}

					img {
						width: 35px;
						height: 35px;
					}
				}

				&__radio {
					border: 0;
					background-color: transparent;
					justify-content: flex-start;

					.input-radio {
						width: 25%;
						display: flex;
						cursor: pointer;

						label {
							margin: 0;
							margin-left: 15px;
							font-family: $gotham-weight-200;
						}

						input {
							width: auto;
							transform: scale(1.2);
						}
					}
				}
			}

			.terminate-btn {
				height: 55px;
				width: 150px;
				color: $c-red;
				border-radius: 8px;
				background-color: $c-white;
				border: 1px solid $c-red;
			}

			.transaction {
				width: 100%;
				padding: 20px;

				&__title {
					color: $c-black;
					text-align: left;
					letter-spacing: 0;
					font-size: $text-size-l;
					font-family: "gotham-book";
				}

				.input-container {
					margin: 15px 0 25px;
					display: flex;
					flex-wrap: wrap;
					align-items: stretch;

					.input-wrapper {
						width: 100%;
						height: 55px;
						max-width: 250px;
						padding: 10px;
						margin-right: 20px;
						border-radius: 8px;
						border: #c9d2de 1px solid;
						display: flex;
						align-items: center;
						justify-content: center;

						.input-field {
							width: 100%;
							text-align: left;
							border: 0;
							color: #999999;
							font-size: 18px;
							outline: none;
							font-family: $gotham-weight-100;
						}
					}

					.custom-divider {
						padding: 0 15px;
						color: $c-black;
						font-family: $gotham-weight-100;
						font-size: $text-size-l;
						border-left: 1px solid #e6e6e6;
						display: flex;
						align-items: center;
						justify-content: center;
					}
				}
			}

			.terminal-table {
				width: 100%;

				.pagination-button {
					padding: 15px;
					justify-content: flex-end;
				}
			}
		}

		.save-pdf {
			border: none;
			border-radius: 5px;
			color: #ffffff;
			background-color: #9d9d9c;
			width: 300px;
			height: 50px;
		}

		.terminate-btn {
			border: none;
			border-radius: 5px;
			color: #ffffff;
			background-color: #fc1818;
			width: 100%;
			height: 40px;
			font-size: 22px;
			font-family: "gotham-bold";
		}

		.res-data {
			height: 37.5px;
			background-color: #ffffff;
			font-size: 25px;
			font-family: "gotham-book";
		}

		.radio-check {
			transform: scale(2);
		}
	}

	.select-input {
		width: 100%;
		height: 100%;
		font-size: 16px;
		font-family: "gotham-book";
		padding: 10px 0px 0px 13px;
		border: 1px solid #d9d9d9;
	}

	.select-input-error {
		width: 100%;
		height: 100%;
		font-size: 16px;
		font-family: "gotham-book";
		padding: 10px 0px 0px 13px;
		border: 1px solid red;
	}

	.input-field {
		border: #e3e3e3 1px solid;
		border-radius: 10px;
		width: 150px;
		height: 37px;
		text-align: center;
	}

	.pagination-button {
		margin-left: auto;
		display: flex;
		align-items: center;
		justify-content: center;

		span {
			font-family: "gotham-light";
		}

		button {
			border: none;
			color: #999999;
			text-decoration: none;
			font-family: "gotham-light";
			background-color: transparent;
		}
	}

	.pop-up {
		position: fixed;
		z-index: 100;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.2);
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.pop-up-data {
		position: center;
		width: 52% !important;
		background-color: white;
		border-radius: 30px;
		text-align: center;
		font-family: "gotham-book";
		font-size: 25px;
		padding: 30px;
	}

	.pop-up-message {
		font-family: "gotham-book";
		font-size: 30px;
		color: $c-navy;
		margin-top: 10px;
		margin-bottom: 10px;
	}

	.pop-up-button {
		margin: 20px 0;
		border: none;
		border-radius: 5px;
		color: $c-white;
		background-color: $c-navy;
		padding: 5px;
		width: 250px;
	}

	.date-input {
		width: calc(100% - 40px);
	}
}
