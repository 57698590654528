.page-initial-approval-matrix {
	padding: 20px;

	.approval-matrix {
		&__header {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		&__title {
			margin: 0;
			color: $c-navy;
			font-size: $text-size-xxxl;
			font-family: $gotham-weight-100;
		}

		&__update-button {
			width: 200px;
			height: 55px;
			color: $c-navy;
			border-radius: 8px;
			border: 1px solid $c-navy;
			background-color: $c-white;
			font-family: $gotham-weight-200;
		}

		&__body {
			height: 100%;
			display: flex;

			.content {
				width: 35%;

				&:first-child {
					.content__header {
						border-top-left-radius: 8px;
					}

					.content__form {
						border-bottom-left-radius: 8px;
					}
				}

				&:last-child {
					width: 65%;

					.content__header {
						border-top-right-radius: 8px;
					}

					.content__form {
						border-bottom-right-radius: 8px;
					}
				}

				&__header {
					width: 100%;
					padding: 20px;
					color: $c-white;
					font-size: $text-size-l;
					font-family: $gotham-weight-100;
					background-color: $c-dark-navy;
				}

				&__form {
					height: 100%;
					padding: 20px;
					background-color: #f6f6f6;
					display: flex;
				}

				&__wrapper {
					&--form {
						padding-right: 30px;
						width: 50%;
					}
				}

				&__label {
					margin: 0;
					color: $c-black;
					font-size: $text-size-body;
					font-family: $gotham-weight-300;
				}

				&__sub-label {
					margin: 0;
					color: lighten($c-black, 20%);
					font-size: $text-size-body;
					font-family: $gotham-weight-200;
				}

				&__sub-title {
					margin: 0;
					color: $c-black;
					font-size: $text-size-l;
					font-family: $gotham-weight-300;
				}

				&__text {
					margin: 0;
					color: lighten($c-black, 20%);
					font-size: $text-size-body;
					font-family: $gotham-weight-200;
				}

				&__input {
					display: flex;
					align-items: center;

					&--ivc {
						margin: 0 0 15px;

						.content__field {
							padding: 10px 15px;
							margin-left: auto;
							border-radius: 8px;

							&--button {
								border-top-right-radius: 0;
								border-bottom-right-radius: 0;
							}
						}
					}
				}

				&__ctos {
					width: 50%;
					max-width: 150px;
					max-height: 55px;
					margin: 0;
					padding: 15px;
					color: $c-white;
					font-size: $text-size-body;
					background-color: $c-dark-navy;
					border: 1px solid $c-dark-navy;
					border-top-left-radius: 8px;
					border-bottom-left-radius: 8px;
				}

				&__score {
					margin: 0;
					padding: 15px 20px;
					font-size: $text-size-l;
					white-space: nowrap;
				}

				&__note {
					margin: 15px 0;
					color: $c-gray;
					font-size: $text-size-m;
				}

				&__field {
					width: 50%;
					max-width: 125px;
					max-height: 55px;
					padding: 15px 20px;
					font-size: $text-size-m;
					background-color: $c-white;
					border: 1px solid #c9d2de;
					border-top-right-radius: 8px;
					border-bottom-right-radius: 8px;
					outline: none;
				}

				&__checkbox {
					margin: 15px 15px 15px 0;
					display: flex;
					align-items: center;
				}

				&__button {
					color: $c-white;
					min-width: 110px;
					padding: 12px;
					background-color: $c-navy;
					border: 1px solid #c9d2de;
					border-top-right-radius: 8px;
					border-bottom-right-radius: 8px;
				}
			}
		}
	}

	.residential-area-pop-up {
		position: fixed;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		overflow-x: hidden;
		overflow-y: scroll;
		background-color: rgba(0, 0, 0, 0.1);
		display: flex;
		align-items: center;
		justify-content: center;

		.pop-up-data {
			margin: 0 auto;
			text-align: center;
			border-radius: 10px;
			background-color: white;
			font-family: $gotham-weight-200;

			.pop-up-data-wrapper {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				justify-content: center;
			}

			.area-container {
				min-width: 150px;
				padding: 10px 15px;
				border-radius: 8px;
				font-size: $text-size-s;
				border: 1px solid #c9d2de;
				font-family: $gotham-weight-100;
			}

			.selected-area-container {
				color: $c-navy;
				border: 1px solid $c-navy;
			}

			.pop-up-data-button-container {
				width: 100%;
			}

			button {
				color: $c-white;
				min-width: 110px;
				margin: 15px 0;
				padding: 10px 15px;
				border-radius: 8px;
				font-size: $text-size-body;
				background-color: $c-navy;
				border: 1px solid #c9d2de;
			}
		}
	}
}
