.app-search-bar {
	position: relative;
	padding: 8px 20px 8px 0px;
	// border-radius: 50px;
	border-radius: 10px;
	background-color: $c-lynx-white;

	&__input {
		border: none;
		background-color: transparent;
		width: 100%;
		padding-left: 28px;
		font-family: $avenir-next-weight-400;
		font-size: $text-size-body;

		&::placeholder {
			font-family: $avenir-next-weight-400;
			font-size: $text-size-body;
			color: $c-gray;
		}

		&:focus {
			outline: none;
			border: none;
		}
	}

	&__icon {
		position: absolute;
		right: 20px;
		top: 10px;
		float: right;
	}

	&__button {
		background: $c-navy;
		position: absolute;
		right: 2px;
		bottom: 1px;
		height: 40px;
		width: 60px;
		border-radius: 10px;
		border: none;
	}
}
